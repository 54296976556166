<template>
	<div>
		<!-- footer -->
		<footer class="footer-wrapper md:footer-responsive ">
			<div v-if="!brand" class="block md:hidden column-footer">
				<div class="flex justify-center">
					<div>
						<img
							src="../assets/icons/logo.png"
							width="160"
							alt="Mejor plataforma del pais"
						/>
					</div>
				</div>

				<p class="copyright">
					TODOS LOS DERECHOS RESERVADOS <br />
					REFÁCIL 2021
				</p>
				<div class="flex justify-center">
					<div>
						<img
							src="../assets/icons/fintech.png"
							width="90"
							alt="Inclusión financiera"
						/>
					</div>
				</div>
			</div>
			<div class="grid grid-cols-1 md:flex justify-between main-container">
				<div class="column-footer text-right">
					<h2>Contáctanos</h2>
					<p>(+57) (6) 3382753</p>
					<p>(+57) 317 230 9003</p>
					<a
						class="mt-2 font-bold cursor-pointer"
						href="mailto:soporte.comercial@superpagos.com"
					>
						soporte.comercial@superpagos.com</a
					>
				</div>
				<div class="column-footer">
					<h2>Horarios</h2>
					<h3>Lunes a Sábado</h3>
					<p>8 a.m. a 10 p.m.</p>
					<h3>Domingos y Festivos</h3>
					<p>9 a.m. a 4 p.m.</p>
				</div>
				<div v-if="!brand" class="hidden md:block column-footer w-1/5">
					<div class="flex justify-center">
						<div>
							<img
								src="../assets/icons/logo.png"
								width="160"
								alt="Microcomercios de Colombia"
							/>
						</div>
					</div>

					<p class="copyright">
						TODOS LOS DERECHOS RESERVADOS <br />
						REFÁCIL 2021
					</p>
					<div class="flex justify-center">
						<div>
							<img
								src="../assets/icons/fintech.png"
								width="90"
								alt="Plataforma transaccional"
							/>
						</div>
					</div>
				</div>
				<div class="column-footer">
					<h2>Nosotros</h2>
					<router-link :to="{ name: 'aboutUs' }">
						<p>
							<span class="link-footer">¿Quiénes somos? </span>
						</p>
					</router-link>
					<router-link :to="{ name: 'multiproduct' }">
						<p>
							<span class="link-footer"> Multiproducto </span>
						</p>
					</router-link>

					<p>
						<span
							class="link-footer"
							@click="
								redirect(
									'https://refacil-co.s3.us-east-2.amazonaws.com/website/docs/tratamiento-datos.pdf'
								)
							"
							>Política de tratamiento de datos
						</span>
					</p>
					<p>
						<span
							class="link-footer"
							@click="
								redirect('https://www.refacil.co/docs/politica-sarlaft.pdf')
							"
							>Sarlaft</span
						>
					</p>
				</div>
				<div class="column-footer">
					<h2>Servicio al cliente</h2>
					<p>
						<span
							class="link-footer"
							@click="redirect('https://www.refacil.co/docs/PQR.pdf')"
							>PQR</span
						>
					</p>
					<p>
						<span
							class="link-footer"
							@click="
								redirect('https://www.refacil.co/docs/preguntas-frecuentes.pdf')
							"
						>
							Preguntas frecuentes
						</span>
					</p>
					<p>
						<span
							class="link-footer"
							@click="
								redirect(
									'https://docs.google.com/forms/d/e/1FAIpQLSe5jNLnyuuKkm8rwSzK7h9mHKmrXnvGqGTjgQD4bWMlViwmZg/viewform'
								)
							"
						>
							Comunícanos tu sospecha
						</span>
					</p>
				</div>
			</div>
		</footer>
	</div>
</template>

<script>
import { mapGetters } from 'vuex';
export default {
	name: 'app-footer',
	props: {
		msg: String,
	},
	data() {
		return {};
	},
	methods: {},
	computed: {
		...mapGetters(['brand']),
	},
};
</script>
<style scoped lang="scss">
.footer-responsive {
	margin-top: 560px;
}
.column-footer {
	text-align: center;
	margin-top: 1.5rem;
	color: #fff;

	.link-footer {
		cursor: pointer;
		border-bottom: 1px solid transparent;
		transition: 0.3s linear;
	}
	.link-footer:hover {
		border-radius: 0px;
		border-bottom: 1px solid #fff;
	}
	h2 {
		margin-top: 0.5rem;
		margin-bottom: 0.4rem;
		font-weight: 700;
		letter-spacing: -0.075rem;
		font-size: 1.4rem;
	}
	span {
		font-size: 0.8rem;
	}
	h3 {
		font-size: 0.85rem;
		font-weight: 600;
		letter-spacing: -0.05rem;
	}
	p,
	a {
		font-size: 0.8rem;
		letter-spacing: -0.05rem;
	}
	.copyright {
		margin: 0.7rem 0 0.5rem 0;
		font-size: 0.55rem;
		letter-spacing: 0.01rem;
	}
}
.footer-wrapper {
	// margin-top: 10vw;
	position: relative;
	text-align: center;
	padding: 10px;
	background-color: var(--darkGray);
	width: 100%;
	margin-top: -30px;
	clip-path: polygon(0 30%, 100% 12%, 100% 100%, 0% 100%);
	background: var(--darkGray);
	padding: 8% 8% 4% 8%;
	// &:before {
	//   content: "";
	//   position: absolute;
	//   left: 50%;
	//   width: 120vw;
	//   transform: translate(-50%, -50%) rotate(-2deg) skew(-5deg);
	//   background: var(--darkGray);
	//   z-index: -1;
	//   top: 0;
	//   height: 10vw;
	// }
}

@media (max-width: 1024px) {
	.footer-wrapper {
		padding: 150px 1% 10% 1%;
		clip-path: polygon(0 20%, 100% 12%, 100% 100%, 0% 100%);
	}
}
</style>

<template>
  <div>
    <slot />
  </div>
</template>

<script>
export default {
  name: "none-layout",
  data() {
    return {};
  },
  components: {},
  mounted() {},
  methods: {}
};
</script>

import { CREATE_PAYMENT, FETCH_PAYMENT } from "../actions.type";
import ApiService from "@/common/api.service";

const state = {};
const getters = {};

const actions = {
  [CREATE_PAYMENT](context, data) {
    return ApiService.post("v1/payments/create/pse/link", data);
  },
  [FETCH_PAYMENT](context, id) {
    return ApiService.get("v1/payments/get", id);
  }
};

const mutations = {};

export default {
  state,
  getters,
  mutations,
  actions
};

<template>
	<div>
		<template v-if="loading">
			<div class="">
				<div class="text-center mt-24">
					<app-loader color="#ea8a01" />
					<p>Cargando</p>
				</div>
			</div>
		</template>
		<template v-else>
			<template v-if="status">
				<div
					class=" w-11/12 mx-auto mt-6"
					:class="!data.brand ? 'details-transaction' : ''"
				>
					<div class="text-center">
						<img
							v-if="!data.brand"
							src="../../assets/icons/approved.png"
							alt="Transacciones digitales"
							class="mx-auto w-12"
						/>
						<img
							v-else
							src="../../assets/icons/approvedBold.png"
							alt="Transacciones digitales"
							class="mx-auto w-12"
						/>
					</div>
					<h3 class="tracking-tighter text-center text-3xl my-4">
						<strong>Detalles de la transacción</strong>
					</h3>
					<div class="grid grid-cols-2 items-center gap-x-6 gap-y-2">
						<div class="text-right" v-if="status.id">
							<p>Código de factura:</p>
						</div>
						<div class="" v-if="status.id">
							<p>{{ status.id }}</p>
						</div>
						<div class="text-right" v-if="status.company">
							<p>Nombre comercial:</p>
						</div>
						<div class="" v-if="status.company && refacil">
							<p>{{ status.company }}</p>
						</div>
						<div class="" v-else>
							<p>{{ data.brand.toUpperCase() }}</p>
						</div>
						<div class="text-right" v-if="status.description">
							<p>Descripción del pago:</p>
						</div>
						<div class="" v-if="status.description">
							<p>{{ status.description }}</p>
						</div>
						<div class="text-right" v-if="status.soliciteDate">
							<p>Fecha y hora:</p>
						</div>
						<div class="" v-if="status.soliciteDate">
							<p>{{ status.soliciteDate }}</p>
						</div>
						<div class="text-right" v-if="status.bankTransactionID">
							<p>CUS (Código único de seguimiento)</p>
						</div>
						<div class="" v-if="status.bankTransactionID">
							<p>{{ status.bankTransactionID }}</p>
						</div>
						<div class="text-right" v-if="status.amount">
							<p>Valor de la transacción:</p>
						</div>
						<div class="" v-if="status.amount">
							<p>${{ money(status.amount) }}</p>
						</div>
						<div class="text-right" v-if="status.iva">
							<p>Iva:</p>
						</div>
						<div class="" v-if="status.iva">
							<p>${{ money(status.iva) }}</p>
						</div>
						<div class="text-right" v-if="status.paymentMethod">
							<p>Medio de pago:</p>
						</div>
						<div class="" v-if="status.paymentMethod">
							<p>{{ status.paymentMethod }}</p>
						</div>
						<div class="text-right" v-if="status.bank">
							<p>Banco:</p>
						</div>
						<div class="" v-if="status.bank">
							<p>{{ status.bank }}</p>
						</div>
						<div class="text-right" v-if="status.nit">
							<p>Nit:</p>
						</div>
						<div class="" v-if="status.nit">
							<p>{{ money(status.nit) }}</p>
						</div>
						<!-- <div class="text-right">
              <p>Ip de origen:</p>
            </div>
            <div class="">
              <p>1234567</p>
            </div> -->
						<div class="text-right">
							<p>Estado:</p>
						</div>
						<div class="">
							<template v-if="status.state == 1">
								<p>Aprobada</p>
							</template>
							<template v-else-if="status.state == 3">
								<p>Rechazada</p>
							</template>
							<template v-else-if="status.state == 2">
								<p>Pendiente</p>
							</template>
							<template v-else-if="status.state == 0">
								<p>Fallida</p>
							</template>
						</div>
					</div>
					<template v-if="refacil && status.state == 2">
            <p class="mt-6 text-justify" v-if=this.isNequi()>
              Por favor confirma la transacción en tu celular cuando te llegue la <strong>notificación push</strong>.
              Si no te ha llegado, verifica que tengas Nequi instalado en tu celular y que ya estés registrado.
            </p>
						<p class="mt-6 text-justify" v-else>
							En este momento su número de Referencia o Factura:
							<strong>{{ status.id }}</strong> presenta un proceso de pago cuya
							transacción se encuentra <strong>PENDIENTE</strong> de recibir
							confirmación por parte de su entidad financiera, por favor espere
							unos minutos y vuelva a consultar más tarde para verificar si su
							pago fue confirmado de forma exitosa. <br />
							Si desea mayor información sobre el estado actual de su operación
							puede comunicarse a nuestras líneas de atención al cliente
							<strong>+57 (317) 230-9003 </strong> o enviar un correo
							electrónico a <strong> soporte.comercial@superpagos.com</strong> y
							preguntar por el estado de la transacción:<strong>
								{{ status.bankTransactionID }}</strong
							>
						</p>
					</template>
				</div>
				<div class="text-center mt-8">
					<button
						type="button"
						class="my-2 btn bg black text-white text-lg py-2 px-12 rounded-full mr-3 hover:black"
						v-on:click="print"
					>
						Imprimir
					</button>
					<!--router-link
						v-if="!data.brand"
						:to="{ name: 'home' }"
						type="button"
						class="my-2 btn bg celeste text-white text-lg py-2 px-12 rounded-full mr-3 hover:celeste"
						>Volver al comercio</router-link
					-->
          <button
              v-if="!data.brand"
              type="button"
              class="my-2 btn bg celeste text-white text-lg py-2 px-12 rounded-full mr-3 hover:celeste"
          >
            <a :href="urlRefacil">Volver al comercio</a>
          </button>
					<button
						v-else
						type="button"
						class="my-2 btn bg bold-company text-white text-lg py-2 px-12 rounded-full mr-3"
					>
						<a :href="urlRedirect">Volver al comercio</a>
					</button>
				</div>
			</template>
			<template v-else>
				<div class="container w-75">
					<div class="text-center mt-12">
						<img
							src="@/assets/icons/warning.png"
							alt="Finanzas digitales"
							class="mx-auto"
						/>
						<img
							class="networks w-50"
							src="https://plataforma.refacil.co/logo.png"
							alt="Accesibilidad financiera"
						/>
						<div>
							<h3 class="font-bold title tracking-tighter">
								¡Oops! Algo salió mal
							</h3>
							<h3 class="text-center mt-6">{{ message }}</h3>
						</div>
					</div>
				</div>
			</template>
		</template>
	</div>
</template>
<script>
import { PSE_STATUS, ACTION_BRAND } from '@/store/actions.type';
import { URL_REDIRECT, URL_REFACIL } from '../../common/config';

export default {
	data() {
		return {
			urlRedirect: URL_REDIRECT,
      urlRefacil: URL_REFACIL,
			status: null,
			message: null,
			loading: true,
			refacil: false,
      nequi: false,
			data: {
				brand: '',
			},
		};
	},
	components: {},
	mounted() {
		this.get();
	},
	methods: {
		get() {
			let data = {
				Resource: {
					reference: this.$route.params.reference,
					PaymentMethod: {
						id: this.$route.params.paymentMethod,
					},
				},
			};
			this.$store
				.dispatch(PSE_STATUS, { data })
				.then(result => {
					this.status = result.data.data;
					this.loading = false;
				})
				.catch(err => {
					this.message = err.message;
					this.loading = false;
				});

			this.isCompany();
		},

		isCompany() {
			if (this.$route.params.company) {
				this.refacil = false;
				(this.data.brand = this.$route.params.company),
					this.$store.dispatch(ACTION_BRAND, this.data);
			} else {
				this.refacil = true;
			}
		},
    isNequi(){
      if (this.$route.params.paymentMethod == 10){
        this.nequi = true;
      }
      return this.nequi;
    },
		print() {
			window.print();
			return true;
		},
	},
};
</script>
<style lang="scss" scoped>
.details-transaction {
	background-image: url('../../assets/icons/logo-watermark.png');
	background-repeat: no-repeat;
	background-size: contain;
	background-position: center;
}
.bold-company {
	background-color: #eb414f;
}
</style>

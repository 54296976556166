<template>
  <div>
    <div class="main-container">
      <app-nav :textColor="true" />
    </div>
    <slot />
    <app-footer />
  </div>
</template>

<script>
import AppNav from '@/components/AppNav.vue'
import AppFooter from '@/components/AppFooter.vue'
export default {
  name: 'app-layout',
  data() {
    return {}
  },
  components: {
    AppNav,
    AppFooter,
  },
  mounted() {},
  methods: {},
}
</script>

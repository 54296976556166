import Vue from 'vue'
import App from './App.vue'
import './registerServiceWorker'
import router from './router'
import store from './store'
import './assets/styles/index.css'
import money from 'v-money'
import Snotify from 'vue-snotify'
import ApiService from './common/api.service'
import VueAnalytics from 'vue-analytics'

import AppLayout from '@/layouts/App.vue'
import DefaultLayout from '@/layouts/Default.vue'
import NoneLayout from '@/layouts/None.vue'

import AppInput from '@/components/form/AppInput.vue'
import AppSearchSelect from '@/components/form/AppSearchSelect.vue'
// import AppButton from "@/components/form/AppButton.vue";
import AppSelect from '@/components/form/AppSelect.vue'
import AppModal from '@/components/general/AppModal.vue'
import AppLoader from '@/components/general/AppLoader.vue'

import * as VueGoogleMaps from 'vue2-google-maps'

Vue.use(VueAnalytics, {
  id: 'UA-137806580-3',
  router,
})

Vue.use(Snotify)
Vue.use(money, { precision: 0, prefix: '$' })
ApiService.init(store)

Vue.component('app-layout', AppLayout)
Vue.component('default-layout', DefaultLayout)
Vue.component('none-layout', NoneLayout)

Vue.component('app-input', AppInput)
// Vue.component("app-button", AppButton);
Vue.component('app-select', AppSelect)
Vue.component('app-modal', AppModal)
Vue.component('app-loader', AppLoader)
Vue.component('app-search-select', AppSearchSelect)

Vue.config.productionTip = false

Vue.use(VueGoogleMaps, {
  load: {
    key: 'AIzaSyDaQZTNT3GYHsZfR9lKmAMSdWoe1bg7aTA',
    libraries: 'places',
  },
})

Vue.mixin({
  methods: {
    money: (n) => {
      var c = 0,
        d = ',',
        t = '.',
        s = n < 0 ? '-' : '',
        i = String(parseInt((n = Math.abs(Number(n) || 0).toFixed(c)))),
        j = (j = i.length) > 3 ? j % 3 : 0
      return (
        s +
        (j ? i.substr(0, j) + t : '') +
        i.substr(j).replace(/(\d{3})(?=\d)/g, `$1${t}`) +
        (c
          ? d +
            Math.abs(n - parseInt(i))
              .toFixed(c)
              .slice(2)
          : '')
      )
    },
    printHtml(html) {
      html = `<html><head>${document.title}<title></title>
      <link rel="stylesheet" href="https://stackpath.bootstrapcdn.com/bootstrap/4.5.2/css/bootstrap.min.css" media='screen,print' onload="printBill()">

      <style media='screen,print'>
      body {
        font-size: 12px;
        max-width: 12cm !important;
        min-width: 12cm!important;
        width: 12cm !important;
      }
      h1 {
        font-size: 15px; 
        font-weight: bold; 
      }
       @page {
        size: 12cm 20cm;
        margin: 0cm;
       }
      </style>
      <script>
       function printBill() {
        window.print();
        document.close();
        window.close();
       }
      </script>
      </head><body>${html}</body></html>`
      const mywindow = window.open('', 'PRINT', 'height=400,width=600')

      mywindow.document.write(html)

      // mywindow.document.close(); // necessary for IE >= 10
      mywindow.focus()
      // mywindow.close();
    },
    redirect(url) {
      window.open(url, '_blank')
    },
  },
})

new Vue({
  router,
  store,
  render: (h) => h(App),
}).$mount('#app')

<template>
  <div class="container-landing">
    <div class="background-img">
      <div class="navbar">
        <router-link :to="{ name: 'home' }">
          <div class="mt-10 sm:mt-2">
            <img
              src="../assets/qrBancolombia/Refacil.png"
              width="150"
              alt="Qr Bancolombia en tu negocio"
            />
          </div>
        </router-link>
        <div class="flex flex-col-reverse text-center sm:flex-row w-50">
          <a :href="`https://plataforma.refacil.co${adValue}`" target="_blank">
            <div class="card-link mt-2 sm:mt-0">
              <label>Soy punto Refácil</label>
              <button class="btn-blue">
                Activar producto
              </button>
            </div>
          </a>
          <a
            :href="`https://plataforma.refacil.co/#/register${adValue}`"
            target="_blank"
          >
            <div class="card-link">
              <label>No soy punto Refácil</label>
              <button class="btn-blue">
                Registrarme
              </button>
            </div>
          </a>
        </div>
      </div>
      <div class="title-qr md:flex justify-between">
        <div>
          <h2>
            Recibe los beneficios <br class="hidden md:block" />
            de activar
          </h2>
          <h2 class="yellow-title">
            QR Bancolombia <br class="hidden md:block" />
            en tu negocio
          </h2>
          <div class="line-orange"></div>
          <h3>Aplica para transferencias desde:</h3>
          <div class="md:flex">
            <div class="mb-5 md:mb-0 md:mr-10">
              <img
                src="../assets/qrBancolombia/Bancolombia.png"
                width="230"
                alt=""
              />
            </div>
            <div>
              <img src="../assets/qrBancolombia/Nequi.png" width="140" alt="" />
            </div>
          </div>
          <p class="blue-text">
            Si ya eres un punto de venta Refácil ingresa al módulo de
            <br class="hidden md:block" />
            <strong>productos especiales </strong> para realizar la activación
            de <strong>QR Bancolombia.</strong>
          </p>
        </div>
        <div class="hidden md:block">
          <img
            src="../assets/qrBancolombia/Cellphone.png"
            class="cellphone-bg"
            width="410"
            alt=""
          />
        </div>
      </div>
    </div>
    <div class="benefits">
      <div>
        <h3>Beneficios</h3>
        <div class="line-orange"></div>
        <div class="grid grid-cols-1 md:grid-cols-3 gap-y-20 mt-14 mb-16">
          <div v-for="(item, index) of benefits" :key="index">
            <div class="item mt-10">
              <img
                :src="require(`@/assets/${item.icon}`)"
                width="80"
                alt="Conectamos microcomercios con tecnologia"
              />
              <p>{{ item.text }}</p>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="definitions">
      <div>
        <div class="grid grid-cols-1 md:grid-cols-2">
          <div class="text-box">
            <h3>¿Qué es?</h3>
            <div class="line-orange"></div>
            <p>
              Es la opción sencilla, ágil y segura de recibir pagos digitales
              <br class="hidden md:block" />
              y vender productos a través de transferencia Bancolombia
              <br class="hidden md:block" />
              sin costos adicionales y sin efectivo, con la que además
              <br class="hidden md:block" />
              podrás recargar la plataforma sin salir de tu negocio.
            </p>
          </div>
          <div class="text-box">
            <div class="line-vertical hidden md:block"></div>
            <h3 class="mt-10 md:mt-0">¿Cómo funciona?</h3>
            <div class="line-orange"></div>
            <p>
              Al finalizar cada venta de recargas en la plataforma o
              <br class="hidden md:block" />
              productos de tu negocio aparecerá la opción de pago por
              <br class="hidden md:block" />
              QR, el cliente final deberá escanear el QR desde su app
              <br class="hidden md:block" />
              Bancolombia, Bancolombia a la mano o Nequi, digitar el
              <br class="hidden md:block" />
              valor a pagar y listo, el saldo se verá reflejado en tu cuenta
              <br class="hidden md:block" />
              refácil al instante.
            </p>
          </div>
        </div>
      </div>
    </div>
    <h3 class="mt-16">Conoce más</h3>
    <div class="line-orange"></div>
    <div class="more-info mb-8">
      <div class="info-item">
        <div class="cont-img">
          <img src="../assets/qrBancolombia/Noticias.jpg" alt="" />
        </div>
        <div class="cont-text">
          <h3 class="news">Noticias</h3>
          <h4>
            Impactaremos más de 10.000 comercios el primer año con los códigos
            QR.
          </h4>
          <p>
            “Bancolombia se unió a Super pagos, una de las fintech con mayor
            presencia en el segmento de microcomercios, para generar
            transacciones con este medio de pago” <br />
          </p>
          <p class="mt-2">
            -La Republica
          </p>
          <button
            @click="
              redirect(
                'https://www.larepublica.co/finanzas/impactaremos-mas-de-10000-comercios-el-primer-ano-con-los-pagos-con-codigos-qr-3187262'
              )
            "
            class="btn-blue"
          >
            Leer artículo completo aquí
          </button>
          <p>
            Este contenido lo puedes ver en:
            <span
              @click="
                redirect(
                  'https://twitter.com/larepublica_co/status/1406974507680735238?s=24'
                )
              "
              >Twitter</span
            >,
            <span
              @click="
                redirect(
                  'https://www.radiosantafe.com/2021/06/22/mas-de-10-mil-tiendas-de-barrio-recibiran-pagos-digitales-a-traves-de-super-pagos-y-bancolombia/'
                )
              "
              >Radio Santafé</span
            >,
            <span
              @click="
                redirect(
                  'https://www.elpaisvallenato.com/2021/06/22/mas-de-10-mil-tiendas-de-barrio-recibiran-pagos-digitales-a-traves-de-super-pagos-y-bancolombia/'
                )
              "
              >El País Vallenato</span
            >,
            <span
              @click="
                redirect(
                  'https://www.canalrcn.com/supertrending/negocios/negocios-en-colombia-pago-digitital-tiendas-de-barrio-381124'
                )
              "
              >RCN</span
            >,
            <span
              @click="
                redirect(
                  'https://www.bcnoticias.com.co/mas-de-10-mil-tiendas-de-barrio-recibiran-pagos-digitales-tras-alianza-de-super-pagos-y-bancolombia/'
                )
              "
              >bcnoticias</span
            >,
            <span
              @click="
                redirect(
                  'https://eldiariodebogota.com/tiendas-de-barrio-pago-electronico/'
                )
              "
              >El diario de Bogotá</span
            >
            y
            <span
              @click="
                redirect(
                  'https://www.portafolio.co/negocios/empresas/mas-de-10-000-tiendas-de-barrio-recibiran-pagos-digitales-553366'
                )
              "
              >Portafolio</span
            >.
          </p>
        </div>
      </div>
      <div class="info-item mt-10">
        <div class="cont-img">
          <img src="../assets/qrBancolombia/Tutorials.png" alt="" />
        </div>
        <div class="cont-text md:mt-9">
          <h3>Tutoriales</h3>
          <h4>
            Ventas usando Códigos QR en nuestra plataforma.
          </h4>
          <p>
            En este tutorial te enseñamos como hacer una venta por medio de
            Código QR a través de nuestra plataforma Refácil. <br />
          </p>
          <p class="mt-2">
            Conoce cómo hacerlo.
          </p>
          <button
            @click="redirect('https://youtu.be/zQ3W8ljATz0')"
            class="btn-blue"
          >
            Ver tutorial aquí
          </button>
        </div>
      </div>
    </div>
    <app-footer />
  </div>
</template>

<script>
import AppFooter from '@/components/AppFooter.vue'

export default {
  name: 'BancolombiaQR',
  components: {
    AppFooter,
  },

  data() {
    return {
      adValue: localStorage.ad ? `?ad=${localStorage.ad}` : '',
      benefits: [
        {
          icon: 'qrBancolombia/1.png',
          text:
            'Recibe pagos digitales sin necesidad de tener cuenta bancaria ni datáfono.',
        },
        {
          icon: 'qrBancolombia/2.png',
          text: 'Realiza / recibe pagos seguros y rápidos.',
        },
        {
          icon: 'qrBancolombia/3.png',
          text: 'Evita el contacto y la manipulación de efectivo.',
        },
        {
          icon: 'qrBancolombia/4.png',
          text: 'Sin montos mínimos.',
        },
        {
          icon: 'qrBancolombia/5.png',
          text: 'Incrementa tus ventas.',
        },
        {
          icon: 'qrBancolombia/6.png',
          text: 'Digitalización del dinero.',
        },
        {
          icon: 'qrBancolombia/7.png',
          text: 'Más posibilidades para los clientes.',
        },
        {
          icon: 'qrBancolombia/8.png',
          text:
            'Permite autogestionar la plataforma refacil sin costos adicionales.',
        },
        {
          icon: 'qrBancolombia/9.png',
          text: 'Tu cliente paga cualquier producto de tu negocio.',
        },
      ],
    }
  },
}
</script>

<style lang="scss" scoped>
.background-img {
  min-height: 100vh;
  background-image: url('../assets/qrBancolombia/background.png');
  background-size: 110%;
  padding: 0 6%;
  background-position-x: -40px;
  background-position-y: -2rem;
  background-repeat: no-repeat;
  .cellphone-bg {
    position: absolute;
    margin-left: -70vh;
  }
}
.navbar {
  padding-top: 2.5rem;
  width: 100%;
  display: flex;
  justify-content: space-between;
}
.card-link {
  max-width: 200px;
  border-radius: 1rem;
  background: #fff;
  -webkit-box-shadow: 0px 5px 8px -1px rgba(111, 111, 111, 0.3);
  -moz-box-shadow: 0px 5px 8px -1px rgba(201, 201, 201, 0.3);
  box-shadow: 0px 5px 8px -1px rgba(114, 114, 114, 0.3);
  margin-right: 0.4rem;
  padding: 0.5rem;
  label {
    font-size: 0.85rem;
    font-weight: 500;
  }
}

.btn-blue {
  letter-spacing: -0.05rem;
  font-size: 0.85rem;
  height: 30px;
  color: #fff;
  border-radius: 2rem;
  padding: 2% 6%;
  background: var(--primaryColor);
  transition: 0.3s;
  width: 90%;
}
.btn-blue:hover {
  background: var(--darkGray);
}
.line-orange {
  width: 73px;
  height: 6px;
  border-radius: 1rem;
  background: #f59200;
  margin: 0 auto 30px auto;
}
.title-qr {
  .blue-text {
    margin-top: 2rem;
    color: #2a6396;
    max-width: 530px;
    line-height: 1.2rem;
    letter-spacing: -0.03rem;
  }
  margin-top: 8%;
  h2 {
    font-size: 3rem;
    letter-spacing: -0.075rem;
    font-weight: 900;
    line-height: 3.4rem;
  }
  .yellow-title {
    color: #ffcf00;
  }
  .line-orange {
    margin: 0;
    width: 200px;
    margin-top: 1rem;
  }
  h3 {
    text-align: left;
    margin-top: 1rem;
    margin-bottom: 1rem;
    font-size: 1.7rem;
  }
}

.benefits {
  display: flex;
  justify-content: center;
  padding: 0 10%;
  h3 {
    margin-top: 4rem;
  }
}

.item {
  margin: 0 auto;
  width: 75%;
  img {
    margin: 0 auto;
  }
  p {
    text-align: center;
    font-size: 0.9rem;
    letter-spacing: -0.05rem;
  }
}

.definitions {
  margin-top: 3rem;
  position: relative;
  padding: 90px 10px 160px 10px;
  height: auto;
  &:before {
    content: '';
    position: absolute;
    top: -6%;
    left: -25%;
    width: 150%;
    height: 90%;
    transform: rotate(8deg) skew(12deg);
    background: linear-gradient(45deg, #eff0f0, #eff0f0);
    z-index: -1;
    margin-top: 20px;
  }

  p {
    text-align: center;
    font-size: 1rem;
    letter-spacing: -0.05rem;
  }
  .line-vertical {
    margin-top: 3rem;
    height: 110px;
    width: 2px;
    border-radius: 1rem;
    background: #000;
    position: absolute;
  }
}
h3,
h4 {
  font-size: 2rem;
  text-align: center;
  font-weight: 900;
}
.more-info {
  margin-top: 3rem;
  padding: 0 15%;
  .info-item {
    display: flex;

    .cont-text {
      width: 80rem;
    }
    .cont-img {
      width: 100%;
    }
    img {
      width: 90%;
      border-radius: 1rem;
      margin: 0 auto;
      max-width: 450px;
    }
    h3 {
      margin-top: -10px;
      text-align: left;
      font-size: 1.2rem;
    }
    h4 {
      line-height: 1.7rem;
      text-align: left;
      font-size: 1.6rem;
      letter-spacing: -0.05rem;
      font-weight: 500;
    }
    p {
      margin-top: 0.5rem;
      font-size: 0.85rem;
      letter-spacing: -0.05rem;
      line-height: 1.1rem;
      span {
        border-bottom: 1px solid #000;
        transition: 0.2s;
      }
      span:hover {
        cursor: pointer;
        border-color: #f59200;
        color: #f59200;
        font-weight: 500;
      }
    }
    .btn-blue {
      margin-top: 0.4rem;
      height: 1.8rem;
      padding: 0 3%;
      width: auto;
      font-size: 0.9rem;
    }
  }
}
// SM

@media (max-width: 639px) {
  .card-link {
    margin-left: 1rem;
    margin-right: 0;
    padding: 3;
    label {
      font-size: 0.75rem;
    }
    .btn-blue {
      height: auto;
      width: 80%;
    }
  }
  .background-img {
    min-height: 0;
    background-image: url('../assets/qrBancolombia/background.png');
    background-size: 300%;
    padding: 0 6%;
    background-position-x: 45%;
    background-position-y: 5rem;
    background-repeat: no-repeat;
  }
  .title-qr {
    margin-top: 15%;
    padding-bottom: 30%;
    h2 {
      font-size: 2.6rem;
      line-height: 3rem;
    }
  }

  .btn-blue {
    padding: 2% 0px;
    width: 90%;
    height: auto;
    margin: 0 auto;
  }
}
@media (max-width: 1024px) {
  .more-info {
    padding: 0 5%;
    .info-item {
      display: grid;
      .cont-text {
        width: 100%;
      }
      .cont-img {
        width: 100%;
      }
      img {
        width: 100%;
      }
      .btn-blue {
        margin: 0 auto;
        width: 100%;
        margin-top: 0.6rem;
      }
      h3 {
        margin-top: 10px;
      }
    }
  }
}
</style>

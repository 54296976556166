<template>
  <div class="">
    <div class="main-container">
      <div class="mx-auto">
        <section class="about-container">
          <div class="sm:px-1 ">
            <div class="mt-20">
              <div class="grid md:grid-cols-2 grid-cols-1">
                <div class="w-auto mx-auto md:px-8 md:p-0">
                  <h2 class="main-title main-title-top">
                    ¡Somos la <br class="block md:hidden" />
                    marca de los microcomercios colombianos!
                  </h2>
                  <p class="main-paragraph md:w-11/12 mt-5">
                    Super Pagos Fintech es una empresa de base tecnológica que
                    comprende varias unidades estratégicas de negocio, que
                    buscan mediante la tecnología soluciones financieras
                    inclusivas que impacten positivamente diferentes sectores
                    del comercio colombiano.
                  </p>
                  <p class="main-paragraph md:w-11/12 mt-5">
                    Entre ellas se encuentra Refácil, la marca líder del grupo
                    Super pagos Fintech. Através de ella llegamos a los tenderos
                    de Colombia facilitandoles la vida a ellos y a sus clientes,
                    poniendo a su disposición una plataforma transaccional,
                    donde comercializan productos de alta demanda y consumo
                    masivo.
                  </p>
                </div>

                <div class="hidden md:block">
                  <img
                    src="../assets/about-us.png"
                    class="img-about-us"
                    alt="Microcomercios colombianos"
                  />
                </div>
              </div>
            </div>
          </div>
        </section>
      </div>
    </div>
    <div class="wrapper-shadow"></div>
    <div class="main-container">
      <!-- Information  -->
      <section class="statics-section">
        <h2 class="main-title main-title-top">
          Estamos en todos los rincones de Colombia.
        </h2>
        <div class="">
          <div class="container-points">
            <div class="item-point">
              <img
                src="../assets/icons/check.png"
                alt="Empresa tecnologica"
                width="55"
              />
              <h3 class="secundary-title">
                28.000 puntos
              </h3>
              <p>de venta en todo el territorio nacional</p>
            </div>
            <div class="item-point margin-center">
              <img
                src="../assets/icons/check.png"
                alt="Plataforma para tenderos"
                width="55"
              />
              <h3 class="secundary-title">
                500 tenderos
              </h3>
              <p>nuevos mes a mes en nuestra red</p>
            </div>
            <div class="item-point">
              <img
                src="../assets/icons/check.png"
                alt="Transacciones Web"
                width="55"
              />
              <h3 class="secundary-title">
                10 millones
              </h3>
              <p>de transacciones al mes</p>
            </div>
          </div>
          <div class="w-full">
            <div class="text-info-points">
              <p>
                En Refácil estamos comprometidos con el crecimiento y la
                digitalización de los microcomercios colombianos, llevamos a sus
                negocios soluciones tecnológicas que permiten diversificar su
                portafolio y aumentar sus ganancias.
              </p>
            </div>
          </div>
        </div>
      </section>
    </div>
    <div>
      <img
        src="../assets/chart.png"
        alt="Reporte de ventas"
        class="img-chart"
        width="1088"
      />
    </div>

    <div class=" main-container">
      <div class="container-title-line">
        <h2 class="main-title">
          Somos parte de las 300 <br class="hidden md:block" />
          empresas más grandes de Colombia
        </h2>
        <p class="sm:mt-2">
          Beneficiamos cada mes cien mil familias de tenderos colombianos,
          poniendo a su disposición <br class="hidden md:block" />
          nuestra plataforma para generar ingresos.
        </p>
      </div>
      <div class="grid grid-cols-1 md:grid-cols-3 px-4 md:px-12">
        <div class="item-line">
          <div class="mt-10 md:mt-0">
            <img
              src="../assets/icons/line-mark.png"
              width="100"
              alt="Empresa mas grande de Colombia"
            />
            <p>
              Presencia en más de 32 <br class="hidden md:block" />
              departamentos 750 municipios.
            </p>
          </div>
        </div>
        <div class="item-line">
          <div>
            <img
              src="../assets/icons/line-mark.png"
              width="100"
              alt="Mayor impacto a microcomercios"
            />
            <p>
              Transformando la vida de 100.000 <br class="hidden md:block" />
              personas ( 20.000 comercios ).
            </p>
          </div>
        </div>
        <div class="item-line">
          <div>
            <img
              src="../assets/icons/line-mark.png"
              width="100"
              alt="Plataforma mas usada"
            />
            <p>
              Llegamos a 3 millones de <br class="hidden md:block" />
              colombianos cada mes.
            </p>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  data() {
    return {}
  },
  components: {},
}
</script>
<style lang="scss" scoped>
.img-about-us {
  position: absolute;
  width: 620px;
  top: 120px;
  margin-left: -50px;
  z-index: -1;
}
.img-chart {
  margin-top: 3rem;
  margin-left: -10px;
}
.about-container {
  padding-left: 5%;
}

.wrapper-shadow {
  transform: rotate(8deg) skew(12deg);
  -webkit-box-shadow: 0px -40px 25px 0px rgba(0, 0, 0, 0.09);
  -moz-box-shadow: 0px -40px 25px 0px rgba(0, 0, 0, 0.09);
  box-shadow: 0px -40px 25px 0px rgba(0, 0, 0, 0.09);
  height: 15vh;
  background: #fff;
  width: 120%;
  margin-left: -20px;
  margin-top: 120px;
}

.statics-section {
  background: #fff;
  .main-title-top {
    background: #fff;
    position: absolute;
    margin-top: -50px;
    padding-left: 8%;
    z-index: 1;
    margin-bottom: 10rem;
    width: 60%;
  }
  .container-points {
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
    padding-top: 5%;
  }
  .item-point {
    text-align: center;
    img {
      margin: 0 auto;
    }
    p {
      font-size: 0.8rem;
      letter-spacing: -0.075rem;
    }
    .secundary-title {
      margin-top: 1.2rem;
    }
  }
  .text-info-points {
    padding: 3% 15% 0 15%;
    text-align: center;
    p {
      line-height: 1.8rem;
      font-size: 1.4rem;
      letter-spacing: -0.09rem;
    }
  }
  .margin-center {
    margin: 0 5%;
  }
}
.container-title-line {
  text-align: center;
  p {
    margin-top: 1rem;
    letter-spacing: -0.075rem;
    line-height: 1.4rem;
    font-size: 1.1rem;
  }
}
.item-line {
  margin-top: 3rem;
  margin-bottom: 3rem;
  display: flex;
  justify-content: center;
  p {
    margin-top: 0.5rem;
    font-size: 1.2rem;
    letter-spacing: -0.075rem;
  }
}

@media (max-width: 1024px) {
  .about-container {
    padding: 0% 7%;
  }
  .main-title {
    line-height: 2.5rem;
    font-size: 2.65rem;
  }
  .statics-section {
    .main-title-top {
      padding: 0 0.5rem;
      width: 100%;
      text-align: center;
      position: relative;
      margin-bottom: 0rem;
    }
    .text-info-points {
      padding: 30px 5% 0px 5%;
    }
  }
  .container-title-line {
    padding: 0 5%;
  }
  .item-line {
    padding: 0 3%;
    justify-content: left;
    margin-top: 1rem;
    margin-bottom: 1rem;
  }
}
</style>

import Vue from "vue";
import Vuex from "vuex";
import payments from "./modules/payments.module";
import transactions from "./modules/transactions.module";

Vue.use(Vuex);

export default new Vuex.Store({
  modules: {
    payments,
    transactions
  }
});

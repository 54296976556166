<template>
  <div class="dealers">
    <div class="main-container">
      <div class="mx-auto">
        <section class="about-container">
          <div class="">
            <div class="mt-20">
              <div class="grid md:grid-cols-2 grid-cols-1">
                <div class="w-auto mx-auto md:px-8 md:p-0">
                  <h2 class="main-title main-title-top">
                    Distribuye nuestros productos y servicios con tu propia red.
                  </h2>
                  <p class="main-paragraph md:w-11/12 mt-5">
                    Con nosotros encuentras una plataforma segura, completa e
                    innovadora, con los estándares de seguridad, para que tu
                    información y tu dinero siempre estén protegidos. Productos
                    de marcas reconocidas y soluciones tecnológicas que harán
                    que tu red venda y gane mucho más.
                  </p>
                  <p class="main-paragraph md:w-11/12 mt-5">
                    Refácil te ofrece respaldo, calidad y excelentes comisiones.
                    <br />
                    Te queremos con nosotros, conviértenos en tu aliado.
                  </p>
                </div>

                <div class="hidden md:block">
                  <img
                    src="../assets/dealer.png"
                    class="img-about-us"
                    alt="Plataforma segura"
                  />
                </div>
              </div>
            </div>
          </div>
        </section>
      </div>
    </div>
    <div class="wrapper-shadow"></div>

    <div class="main-container">
      <div class="benefits-title">
        <div>
          <!-- Benefits -->
          <section>
            <div>
              <div class="md:w-10/12">
                <h2 class="main-title">
                  Beneficios de ser <br class="hidden md:block" />
                  distribuidor de Refácil
                </h2>
              </div>
            </div>
          </section>

          <!-- Contact advisor -->
        </div>
      </div>
    </div>

    <div class="flex justify-center">
      <div class="benefits-container">
        <div class="flex mt-2">
          <div class="check-img">
            <img
              src="../assets/icons/check.png"
              alt="Cantidad de productos"
              width="35"
            />
          </div>
          <p>
            Tu y tu red tienen a disposición una
            <strong>plataforma completa</strong> y con gran
            <strong>variedad de productos.</strong>
          </p>
        </div>
        <div class="flex mt-2">
          <div class="check-img">
            <img
              src="../assets/icons/check.png"
              alt="Economia digital"
              width="35"
            />
          </div>
          <p>
            Puedes usar la App
            <strong>Refácil totalmente gratis</strong> usando la
            <strong>SIM CLARO.</strong>
          </p>
        </div>

        <div class="flex mt-2">
          <div class="check-img">
            <img
              src="../assets/icons/check.png"
              alt="Tecnologia financiera"
              width="35"
            />
          </div>
          <p>
            Puedes <strong>usar la misma APP</strong> Refácil como
            <strong> SIM distribuidor o punto de venta,</strong> no importa cuál
            sea tu perfil.
          </p>
        </div>
        <div class="flex mt-2">
          <div class="check-img">
            <img
              src="../assets/icons/check.png"
              alt="Plataforma facil de usar"
              width="35"
            />
          </div>

          <p>
            Puedes
            <strong>revisar tu cartera a través de tu celular</strong> con la
            APP Refácil.
          </p>
        </div>
        <div class="flex mt-2">
          <div class="check-img">
            <img
              src="../assets/icons/check.png"
              alt="Plataforma de facil acceso"
              width="35"
            />
          </div>

          <p>Creas tu <strong>propia red de tenderos.</strong></p>
        </div>
        <div class="flex mt-2">
          <div class="check-img">
            <img
              src="../assets/icons/check.png"
              alt="Plataforma con mejor rendimiento"
              width="35"
            />
          </div>

          <p>
            Tendrás a disposición un canal de
            <strong>comunicación directa con nosotros</strong> en horario
            extendido.
          </p>
        </div>
        <div class="flex mt-2">
          <div class="check-img">
            <img
              src="../assets/icons/check.png"
              alt="Finanzas para negocios"
              width="35"
            />
          </div>

          <p>
            Recibirás <strong>capacitación completa</strong> de nuestros
            productos.
          </p>
        </div>
        <div class="flex mt-2">
          <div class="check-img">
            <img
              src="../assets/icons/check.png"
              alt="Plataforma con capacitaciones"
              width="35"
            />
          </div>

          <p>
            En la plataforma encontrarás
            <strong>publicidad que podrás usar en tu red.</strong>
          </p>
        </div>
        <div class="flex mt-2">
          <div class="check-img">
            <img
              src="../assets/icons/check.png"
              alt="Publicidad para negocios"
              width="35"
            />
          </div>

          <p>
            Podrás recargar tu
            <strong>plataforma con más de 23 convenios</strong> que tenemos para
            ti.
          </p>
        </div>
        <div class="flex mt-2">
          <div class="check-img">
            <img
              src="../assets/icons/check.png"
              alt="Estadisticas para negocios"
              width="35"
            />
          </div>

          <p>
            Podrás <strong>descargar reportes</strong> que te ayudarán a
            visualizar el movimiento de toda tu red.
          </p>
        </div>
        <div class="flex mt-2">
          <div class="check-img">
            <img
              src="../assets/icons/check.png"
              alt="Ganar dinero para negocios"
              width="35"
            />
          </div>

          <p>
            <strong>Ganarás comisión de la comisión que recibes</strong>
            con nuestra versión multiproducto.
          </p>
        </div>
      </div>
    </div>

    <div class="wrapper-shadow-invert"></div>
    <section>
      <div class="container-dealers main-container">
        <div class="dealers-title">
          <h2 class="main-title">
            9 de cada 10 distribuidores nos recomiendan.
          </h2>
          <p class="mt-2">
            Resultados sacados de la última encuesta de satisfacción del
            cliente. <br />
          </p>
          <p class="mt-2">
            Vive tu mismo la experiencia y disfruta de los excelentes beneficios
            que Refácil tiene para todos sus distribuidores.
          </p>
          <div class=" h-28">
            <button class="btn-gray-main mt-6">
              Contactar un asesor
            </button>
          </div>
        </div>
        <div class="hidden md:block">
          <div class="testimony-wrapper">
            <div>
              <img
                src="../assets/quotation-marks.png"
                alt="Genera mas ingresos"
                width="160"
              />
            </div>
            <p class="text-base">
              “Estamos muy felices, ya que podemos entrar a puntos de venta,
              nuevos productos para generar más ingresos. Gracias por
              permitirnos ser parte de su marca”
            </p>
            <div class="footer-point">
              <span>
                MARIA VITALIA VIVEROS
              </span>
              <p class="point">Punto de venta</p>
            </div>
          </div>
        </div>
      </div>
    </section>
  </div>
</template>

<script>
export default {
  data() {
    return {}
  },
  components: {},
}
</script>
<style lang="scss">
.container-dealers {
  p {
    line-height: 1.2rem;
    letter-spacing: -0.075rem;
    font-weight: 300;
  }
  .btn-gray-main {
    padding: 1rem 2.5rem;
    border-radius: 2rem;
    font-size: 1.1rem;
  }

  display: flex;
  justify-content: center;
  padding: 0 7%;
  .testimony-wrapper {
    -webkit-box-shadow: 5px 5px 11px -2px rgba(99, 99, 99, 0.29);
    -moz-box-shadow: 5px 5px 11px -2px rgba(99, 99, 99, 0.29);
    box-shadow: 5px 5px 11px -2px rgba(99, 99, 99, 0.29);
    text-align: center;
    background: #eee;
    margin-top: -80px;
    width: 510px;
    border-radius: 20px;
    padding: 80px 70px 70px 70px;
    position: relative;
    &:before {
      content: '';
      position: absolute;
      bottom: -30px;
      left: 0;
      width: 0;
      height: 0;
      border-right: 30px solid transparent;
      border-top: 30px solid #eee;
      border-left: 30px solid #eee;
      border-bottom: 30px solid transparent;
    }
    img {
      margin-top: -120px;
      margin-left: -90px;
      margin-bottom: 50px;
    }
    p {
      font-size: 1.2rem;
      letter-spacing: -0.075rem;
      font-weight: 400;
    }
    .footer-point {
      margin-top: 30px;
      top: 50px;
      span {
        font-weight: 800;
        font-size: 0.9rem;
        letter-spacing: 2px;
      }
      p {
        font-size: 0.9rem;
        letter-spacing: 2px;
      }
    }
  }
}

.benefits-title {
  width: 100%;
  position: absolute;
  margin-top: -120px;
  padding-left: 8%;
}
.benefits-container {
  background: #fff;
  z-index: 2;
  .container-checks {
    display: flex;
    justify-content: center;
  }
  p {
    font-size: 1.2rem;
    letter-spacing: -0.075rem;
    margin-top: 0.4rem;
  }
  img {
    margin-right: 1rem;
  }
}

.wrapper-shadow-invert {
  transform: rotate(8deg) skew(12deg);
  -webkit-box-shadow: 0px 40px 25px 0px rgba(101, 101, 101, 0.09);
  -moz-box-shadow: 0px 40px 25px 0px rgba(101, 101, 101, 0.09);
  box-shadow: 0px 40px 25px 0px rgba(101, 101, 101, 0.09);
  height: 10vh;
  background: #fff;
  width: 200%;
  margin-left: -100px;
  margin-top: 130px;
}

.wrapper-shadow {
  transform: rotate(8deg) skew(12deg);
  -webkit-box-shadow: 0px -40px 25px 0px rgba(0, 0, 0, 0.09);
  -moz-box-shadow: 0px -40px 25px 0px rgba(0, 0, 0, 0.09);
  box-shadow: 0px -40px 25px 0px rgba(0, 0, 0, 0.09);
  height: 22vh;
  background: #fff;
  width: 120%;
  margin-left: -20px;
  margin-top: 130px;
}
.img-about-us {
  position: absolute;
  width: 750px;
  top: 85px;
  margin-left: -150px;
  z-index: -1;
}
.img-chart {
  margin-top: 3rem;
  margin-left: -10px;
}
.about-container {
  padding-left: 5%;
}
.testimony-wrapper {
  z-index: 3;
}
.dealers-title {
  padding-right: 1rem;
}

@media (max-width: 1024px) {
  .about-container {
    padding: 0 7%;
    .main-title {
      font-size: 2.8rem;
    }
  }
  .wrapper-shadow {
    margin-top: 80px;
  }
  .benefits-title {
    padding-top: 1rem;
    position: relative;
    .main-title {
      font-size: 2.8rem;
    }
  }
  .benefits-container {
    margin-top: 1rem;
    padding: 0 7%;
    p {
      line-height: 1.4rem;
    }
    .check-img {
      min-width: 40px;
      max-width: 40px;
    }
  }
  .container-dealers {
    padding: 0 7%;
    .testimony-wrapper {
      margin-top: 4rem;
    }
    display: block;
  }
  .wrapper-shadow-invert {
    margin-top: 50px;
  }
  .dealers-title {
    padding-right: 0rem;
    margin-top: 4rem;
  }
}
</style>

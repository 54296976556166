<template>
  <div>
    <nav class="">
      <div class=" sm:px-3 container-nav md:px-12">
        <div class="relative  items-center  h-16">
          <div class="absolute inset-y-0 left-0  items-center block md:hidden">
            <!-- Mobile menu button-->
            <button
              class=" mt-9 ml-3  inline-flex items-center justify-center p-2 rounded-md  icon-menu "
              aria-expanded="true"
              v-on:click="showMenu = !showMenu"
            >
              <span class="sr-only">Open main menu</span>
              <!-- Icon when menu is closed. -->
              <!--
                Heroicon name: menu

                Menu open: "hidden", Menu closed: "block"
              -->
              <svg
                class="block h-7 w-7"
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 20 20"
                stroke="currentColor"
                aria-hidden="true"
              >
                <path
                  stroke-linecap="round"
                  stroke-linejoin="round"
                  stroke-width="2"
                  d="M4 6h16M4 12h16M4 18h16"
                />
              </svg>
              <!-- Icon when menu is open. -->
              <!--
                Heroicon name: x

                Menu open: "block", Menu closed: "hidden"
              -->
              <svg
                class="hidden h-6 w-6"
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 24 24"
                stroke="currentColor"
                aria-hidden="true"
              >
                <path
                  stroke-linecap="round"
                  stroke-linejoin="round"
                  stroke-width="2"
                  d="M6 18L18 6M6 6l12 12"
                />
              </svg>
            </button>
          </div>

          <div
            class=" flex-1 flex items-center justify-center md:items-stretch sm:justify-start"
          >
            <div class="flex-shrink-0 flex items-center">
              <template v-if="logo == 'logo.png'">
                <a href="https://www.refacil.co/" class="pt-6">
                  <img
                    class="block small-logo  md:hidden mt-2"
                    alt="La mejor plataforma transaccional Colombiana"
                    width="150"
                    src="../assets/icons/logo.png"
                  />
                </a>
                <a href="https://www.refacil.co/" class="pt-6">
                  <img
                    class="hidden logo md:block mt-2"
                    width="185"
                    src="../assets/icons/logo.png"
                    alt="Crecimiento de tu negocio"
                  />
                </a>
              </template>
              <template v-else>
                <a href="https://www.refacil.co/" class="pt-6">
                  <img
                    class="block small-logo md:hidden mt-2"
                    width="150"
                    alt="Inclusion financiera"
                    src="../assets/icons/logoW.png"
                  />
                </a>
                <a href="https://www.refacil.co/" class="pt-6">
                  <img
                    class="hidden logo md:block mt-2"
                    width="185"
                    src="../assets/icons/logoW.png"
                    alt="Plataforma de facil uso"
                  />
                </a>
              </template>
            </div>
            <div class="hidden md:block sm:ml-6 w-full ">
              <div class="flex space-x-3 mt-12 justify-between">
                <div class="flex">
                  <!-- Current: "bg-gray-900 text-white", Default: "text-gray-300 hover:bg-gray-700 hover:text-white" -->
                  <router-link
                    :to="{ name: 'home' }"
                    :class="!textColor ? 'text-white' : ''"
                    class="md:px-3 rounded-md text-base lg:text-lg font-medium link-nav"
                  >
                    <span :class="!textColor ? 'line-white' : 'line-gray'">
                      Inicio
                    </span>
                  </router-link>

                  <router-link
                    :to="{ name: 'aboutUs' }"
                    :class="!textColor ? 'text-white' : 'text-gray-800'"
                    class="md:px-3 rounded-md text-base lg:text-lg font-medium link-nav"
                  >
                    <span :class="!textColor ? 'line-white' : 'line-gray'">
                      ¿Quiénes somos?
                    </span>
                  </router-link>

                  <router-link
                    :to="{ name: 'multiproduct' }"
                    :class="!textColor ? 'text-white' : 'text-gray-800'"
                    class="md:px-3 rounded-md text-base lg:text-lg font-medium link-nav"
                  >
                    <span :class="!textColor ? 'line-white' : 'line-gray'">
                      Multiproducto
                    </span>
                  </router-link>

                  <router-link
                    :to="{ name: 'dealers' }"
                    :class="!textColor ? 'text-white' : 'text-gray-800'"
                    class="md:px-3 rounded-md text-base lg:text-lg font-medium link-nav"
                  >
                    <span :class="!textColor ? 'line-white' : 'line-gray'">
                      Distribuidores
                    </span>
                  </router-link>
                </div>

                <div class="flex main-action">
                  <a
                    :href="`https://plataforma.refacil.co/${adValue}`"
                    target="_blank"
                    :class="!textColor ? 'text-white' : 'text-gray-800'"
                    class="text-white text-base lg:text-lg font-medium link-login link-nav"
                  >
                    <span :class="!textColor ? 'line-white' : 'line-gray'">
                      Iniciar sesión
                    </span>
                  </a>
                  <div
                    class="separator"
                    :class="!textColor ? 'bg-white' : 'bg-gray-800'"
                  ></div>
                  <div>
                    <a
                      :href="
                        `https://plataforma.refacil.co/#/register${adValue}`
                      "
                      target="_blank"
                      class="btn-gray-main link-nav"
                    >
                      <span>
                        Registrarme
                      </span>
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </nav>

    <transition name="fade">
      <template v-if="showMenu">
        <div class="box-menu p-8">
          <div class="float-right">
            <button v-on:click="showMenu = false" class="font-light text-xl">
              <i class="material-icons">close</i>
            </button>
          </div>
          <ul class="mt-6">
            <li class="my-3" v-on:click="showMenu = false">
              <router-link :to="{ name: 'home' }" class="item-responsive-link"
                >Inicio
              </router-link>
            </li>
            <li class="my-3" v-on:click="showMenu = false">
              <router-link
                :to="{ name: 'aboutUs' }"
                class="item-responsive-link"
                >¿Quiénes somos?
              </router-link>
            </li>
            <li class="my-3" v-on:click="showMenu = false">
              <router-link
                :to="{ name: 'multiproduct' }"
                class="item-responsive-link"
                >Multiproducto
              </router-link>
            </li>
            <li class="my-3" v-on:click="showMenu = false">
              <router-link
                :to="{ name: 'dealers' }"
                class="item-responsive-link"
                >Distribuidores
              </router-link>
            </li>

            <li class="mt-5 text-center main-action">
              <a
                :href="`https://plataforma.refacil.co/${adValue}`"
                target="_blank"
                class="bg-gray-200 text-lg font-normal py-2 px-5 rounded-full font-bold mr-4 hover:bg-gray-800 hover:text-white block md:inline-block w-full md:w-auto"
                >Iniciar sesión
              </a>

              <a
                :href="`https://plataforma.refacil.co/#/register${adValue}`"
                target="_blank"
                class="btn-gray-main block mt-2 md:inline-block w-full md:w-auto"
              >
                Registrarme
              </a>
            </li>
          </ul>
        </div>
      </template>
    </transition>
  </div>
</template>

<script>
export default {
  name: 'app-nav',
  props: {
    textColor: { type: Boolean, required: false },
  },
  data() {
    return {
      adValue: localStorage.ad ? `?ad=${localStorage.ad}` : '',
      showMenu: false,
      logo: null,
    }
  },
  mounted() {
    this.getLogo(this.$route)
  },
  methods: {
    getLogo(route) {
      this.logo = `${route.meta.logo}`
    },
  },
  watch: {
    $route(to) {
      this.getLogo(to)
    },
    '$route.query.ad': {
      immediate: true,
      handler(value) {
        if (value) {
          localStorage.ad = value
          this.adValue = `?ad=${value}`
        }
      },
    },
  },
}
</script>
<style scoped lang="scss">
.btnGray {
  background: var(--darkGray);
}
nav {
  z-index: 10;
}

.box-menu {
  background: #fff;
  z-index: 10;
  position: absolute;
  top: 70px;
  left: 10px;
  width: 90%;
  border-radius: 10px;
  transition: 0.5s;
  box-shadow: 0 50px 100px -20px rgba(50, 50, 93, 0.25),
    0 30px 60px -30px rgba(0, 0, 0, 0.3);
  margin: 10px;
}

.link-nav {
  z-index: 1;
  letter-spacing: -0.05rem;
  font-size: 16px;
  font-weight: 400;
}
.link-nav .line-white {
  border-bottom: 1px solid transparent;
  transition: 0.3s linear;
}

.link-nav .line-white:hover {
  border-radius: 0px;
  border-bottom: 1px solid #fff;
}
.link-nav .line-gray {
  border-bottom: 1px solid transparent;
  transition: 0.3s linear;
}

.link-nav .line-gray:hover {
  border-radius: 0px;
  border-bottom: 1px solid var(--darkGray);
}
.router-link-exact-active .line-gray {
  border-bottom: 1px solid var(--darkGray);
}
.router-link-exact-active .line-white {
  border-bottom: 1px solid #fff;
}
.separator {
  width: 1px;
  height: 20px;
  margin: 5px 1rem 0 1rem;
}
.main-action {
  .btn-gray-main {
    font-weight: 600;
    padding: 0.7rem 1.5rem;
  }
}

@media (max-width: 1024px) {
  .container-nav {
    padding: 0 2%;
  }
  .logo {
    width: 150px;
  }
  .link-nav {
    font-size: 0.9rem;
  }
  .item-responsive-link {
    font-size: 1.2rem;
    letter-spacing: -0.075rem;
  }
}

@media (max-width: 767px) {
  @media (min-width: 639px) {
    .small-logo {
      margin-left: 120px;
    }
  }
}
</style>

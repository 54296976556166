<template>
  <div class="my-2">
    <label for="country" class="block text-sm font-medium text-gray-700">
      {{ label }}
    </label>
    <div class="shadow">
      <select
        :class="extraClass"
        v-model="inputValue"
        autocomplete="country"
        class="mt-1 block w-full py-2 px-3 bg-white select-radius"
      >
        <slot></slot>
      </select>
    </div>
  </div>
</template>

<script>
export default {
  name: 'app-select',
  props: {
    label: { type: String, required: false },
    value: { required: false },
    extraClass: { type: String, required: false },
  },
  data() {
    return {
      inputValue: '',
    }
  },
  mounted() {
    this.inputValue = this.value
  },
  methods: {
    change() {
      this.$emit('input', this.inputValue)
    },
  },
  watch: {
    inputValue() {
      this.change()
    },
    value(a) {
      if (a != this.inputValue) {
        this.inputValue = a
      }
    },
  },
}
</script>

<style lang="scss">
.shadow {
  box-shadow: -2px 3px 6px 2px rgba(128, 98, 96, 0.16);
  select {
    border: none;
    padding: 12px 5px;
    color: #374251;
    font-size: 16px;
    line-height: 1.25rem;
    border-radius: 20px;
  }
}
</style>

<template>
	<div>
		<nav class="pt-6">
			<div class="mx-auto container sm:px-3 lg:px-8">
				<div class="relative flex items-center justify-between h-16">
					<div
						class="flex-1 flex items-center justify-center sm:items-stretch sm:justify-start"
					>
						<div class="flex-shrink-0 flex items-center">
							<a href="https://www.refacil.co/">
								<img
									v-if="!brand"
									class="block lg:hidden h-8 w-auto"
									src="../assets/icons/logo.png"
									alt="Mejor plataforma del pais"
								/>
								<img
									v-else
									class="block lg:hidden h-8 w-auto"
									src="../assets/icons/bold.png"
									alt="Mejor plataforma del pais"
								/>
							</a>
							<a href="https://www.refacil.co/">
								<img
									v-if="!brand"
									class="hidden lg:block h-auto w-56"
									src="../assets/icons/logo.png"
									alt="Crecimiento de negocio"
								/>
								<img
									v-else
									class="hidden lg:block h-8 w-auto"
									src="../assets/icons/bold.png"
									alt="Mejor plataforma del pais"
								/>
							</a>
						</div>
						<div class="hidden sm:block sm:ml-6"></div>
					</div>
					<div
						class=" inset-y-0 right-0 items-center pr-2  hidden sm:block sm:ml-6 "
					>
						<div class="mt-4">
							<p class="font-medium text-lg inline-block mr-6">
								#SomosMultiproducto
							</p>
							<a
								href="https://www.facebook.com/SomosRefacilOficial"
								target="_blank"
							>
								<img
									alt="Plataforma con buena atencion al cliente"
									src="../assets/icons/fb.png"
									class="inline-block img-social mr-2"
								/>
							</a>
							<a href="https://www.instagram.com/refacil.co/" target="_blank">
								<img
									alt="Productos para tu negocio"
									src="../assets/icons/ig.png"
									class="inline-block img-social"
								/>
							</a>
						</div>

						<div class="mt-2" v-if="!brand">
							<p class="font-medium text-lg inline-block mr-6">
								Paga seguro a través de
							</p>
							<a href="https://apagar.co/" target="_blank">
								<img
									src="../assets/icons/logoPay.png"
									alt="Pasarela de pagos"
									class="inline-block logoPay"
								/>
							</a>
						</div>
					</div>
				</div>
			</div>
		</nav>
		<div class="page">
			<slot />
		</div>
		<app-footer />
	</div>
</template>

<script>
// import AppNav from "@/components/AppNav.vue";
import { mapGetters } from 'vuex';
import AppFooter from '@/components/AppFooter.vue';

export default {
	name: 'app-layout',
	data() {
		return {};
	},
	components: {
		// AppNav,
		AppFooter,
	},
	mounted() {},
	methods: {},
	computed: {
		...mapGetters(['brand']),
	},
};
</script>
<style>
.img-social {
	width: 36px;
}
.page {
	min-height: calc(100vh - 300px);
}

.logoPay {
	height: 25px;
}
</style>

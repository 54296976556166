<template>
  <div class="my-2">
    <template v-if="label">
      <label for="first_name" class="block text-sm font-medium text-gray-700">
        {{ label }}
      </label>
    </template>

    <template v-if="type == 'money'">
      <money
        class="block text-sm font-medium text-gray-700"
        v-model="inputValue"
        :class="extraClass"
      ></money>
    </template>
    <template v-else>
      <input
        :type="type"
        v-model="inputValue"
        :class="extraClass"
        :placeholder="placeholder"
        v-on:keyup="keyup"
        class="block w-full sm:text-sm rounded-md placeholder-gray-700"
      />
    </template>
  </div>
</template>

<script>
export default {
  name: "app-input",
  props: {
    label: { type: String, required: false },
    type: { type: String, required: false, default: "text" },
    placeholder: { type: String, required: false },
    value: { required: false },
    extraClass: { type: String, required: false }
  },
  data() {
    return {
      inputValue: ""
    };
  },
  mounted() {
    this.inputValue = this.value;
  },
  methods: {
    change() {
      this.$emit("input", this.inputValue);
    },
    keyup() {
      this.$emit("keyup");
    }
  },
  watch: {
    inputValue() {
      this.change();
    },
    value(a) {
      if (a != this.inputValue) {
        this.inputValue = a;
      }
    }
  }
};
</script>

<style lang="scss">
input {
  border: none;
  box-shadow: -2px 3px 6px 2px rgba(128, 98, 96, 0.16);
  padding: 10px 5px;
  // border: none;
  //   box-shadow: -2px 3px 6px 2px rgb(202 202 202 / 47%);
  //   padding: 10px 5px;
}
</style>

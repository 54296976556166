// var env = 'production';
// var env = "development";
var env = 'testing';
var server = '';
let API_APAGAR;
let URL_REDIRECT;
let URL_REFACIL;

if (env == 'production') {
	server = 'https://plataforma.refacil.co/api/';
	API_APAGAR = 'https://api.apagar.co/api/';
	URL_REFACIL = 'https://plataforma.refacil.co/';
	URL_REDIRECT = 'https://plataforma.bold.refacil.co/';
} else if (env == 'testing') {
	server = 'https://testing.refacil.co/api/';
	URL_REFACIL = 'https://testing.refacil.co/';
	URL_REDIRECT = 'https://testing.bold.refacil.co/';
	API_APAGAR = 'https://sandbox.apagar.co/api/';
}

export const ENV = env;

export const API_URL = server;

export { API_APAGAR };

export const BASE_URL = server;

export const GLOBAL_VERSION = '1.0.0b';

export const PLATFORM = 'WEB';

export { URL_REDIRECT };

export { URL_REFACIL };

export default API_URL;

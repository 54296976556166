import Vue from 'vue'
import axios from 'axios'
import VueAxios from 'vue-axios'
import { API_URL, API_APAGAR } from '@/common/config'
// import { SET_ERRORS } from "@/store/mutations.type";

var _Store = null
var _Vue = null

const ApiService = {
  init(Store) {
    _Store = Store
    Vue.use(VueAxios, axios)
    Vue.axios.defaults.baseURL = API_URL
    this.setHeader()
  },

  setHeader() {
    Vue.axios.defaults.headers.common['Content-Language'] = 'es'
    //Vue.axios.defaults.headers.common["channel"] = "web";

    var token = _Store.getters.user ? _Store.getters.user.token : ''
    if (token) {
      Vue.axios.defaults.headers.common['Authorization'] = `Token ${token}`
    }
  },

  setVue(vue) {
    _Vue = vue
  },

  query(resource, params) {
    return Vue.axios.get(resource, params).catch((error) => {
      throw new Error(`[RWV] ApiService ${error}`)
    })
  },

  _handleError(error) {
    var result = error.response.data
    if (error.response.status == 401) {
      window.clearInterval(window.threadRealTime)
      _Vue.$root.$children[0].unauthorized(error.response)
      // window.location = "/";
    }
    // else if (error.response.status == 400) {
    //   _Store.commit(SET_ERRORS, error);
    // }

    return Promise.reject(result)
  },

  get(resource, slug = '') {
    return Vue.axios
      .get(`${resource}/${slug}`)
      .then((result) => {
        return Promise.resolve(result.data)
      })
      .catch(this._handleError)
  },

  download(resource, slug = '') {
    return Vue.axios
      .get(`${resource}/${slug}`, { responseType: 'arraybuffer' })
      .then((result) => {
        return Promise.resolve(result.data)
      })
      .catch(this._handleError)
  },

  downloadPost(resource, data) {
    return Vue.axios
      .post(`${resource}`, data, { responseType: 'arraybuffer' })
      .then((result) => {
        return Promise.resolve(result.data)
      })
      .catch(this._handleError)
  },

  async post(resource, params) {
    try {
      if (params) {
        params._channel = 'web'
      }
      let result = await Vue.axios.post(`${resource}`, params)
      return result.data
    } catch (err) {
      return this._handleError(err)
    }
  },
  async postApagar(resource, params) {
    try {
      if (params) {
        params._channel = 'web'
      }
      Vue.axios.defaults.baseURL = API_APAGAR
      let result = await Vue.axios.post(`${resource}`, params)
      return result.data
    } catch (err) {
      return this._handleError(err)
    }
  },

  postMultiPart(resource, params) {
    return Vue.axios
      .post(`${resource}`, params, {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      })
      .then((result) => {
        return Promise.resolve(result.data)
      })
      .catch(this._handleError)
  },
}

export default ApiService

<template>
  <div>
    <div class="layout-container">
      <div class="main-container">
        <!-- home -->
        <section id="home">
          <div class="nav-wrapper sm:px-1">
            <div>
              <app-nav :textColor="false" />
              <div class="mt-20">
                <div class="grid md:grid-cols-2 grid-cols-1">
                  <div class="lg:w-10/12 w-auto container-title">
                    <h1 class="font-bold title">
                      Incrementa<br />
                      las ventas de <br />
                      tu negocio <br />
                      fácil y rápido.
                    </h1>
                    <p class="tracking-tighter leading-5 my-4">
                      Somos el aliado perfecto de los micro comercios
                      <br class="hidden md:block" />
                      del país. Diversificamos y hacemos crecer tu
                      <br class="hidden md:block" />

                      negocio de una forma rápida y segura.
                    </p>

                    <div>
                      <button
                        @click="
                          redirectWithAd(
                            'https://plataforma.refacil.co/#/register'
                          )
                        "
                        target="_blank"
                        class="btn-gray-main"
                      >
                        Empezar ahora
                      </button>

                      <button
                        class="btn-light-main 
                       mt-6 md:mt-4"
                        v-on:click="showModalContact = !showModalContact"
                      >
                        Contactar un asesor
                      </button>
                    </div>
                  </div>

                  <div class="relative hidden md:block">
                    <img
                      src="../assets/initial.png"
                      class="absolute img-initial  mr-14 mt-11"
                      width="480"
                      alt="Tecnologia Financiera"
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>

        <!-- allies -->
        <section>
          <div class="text-center">
            <h2 class="allies-p">ALGUNOS DE NUESTROS ALIADOS</h2>
            <img
              src="../assets/allies.png"
              alt="Mejores aliados bancarios"
              width="959"
              class="mt-6 mx-auto"
            />
          </div>
        </section>

        <!-- features -->
        <section>
          <div class="grid grid-cols-1 md:flex mt-8 container-items">
            <div class="mx-auto text-center mt-14">
              <img
                src="../assets/usability.png"
                alt="Plataforma facil de usar"
                width="74"
                class="mx-auto"
              />
              <h3 class="leading-none tracking-tighter mt-2 font-bold text-3xl">
                Facilidad de Uso
              </h3>
              <p class="text-center tracking-tighter">
                Ahorra tiempo con una plataforma intuitiva, dinámica y atractiva
                que aumenta tu productividad.
              </p>
            </div>
            <div class="hidden md:block separator mt-16"></div>
            <div class="mx-auto text-center mt-4 md:mt-0">
              <div class="icon-arrow hidden md:flex">
                <div>
                  <img
                    src="../assets/icons/arrow.png"
                    width="24"
                    alt="Finanzas con tecnologia"
                  />
                </div>
              </div>
              <img
                src="../assets/benefits.png"
                class="mx-auto"
                alt="Portafolio de productos"
                width="82"
              />
              <h3 class="leading-none tracking-tighter font-bold mt-4 text-3xl">
                Beneficios
              </h3>
              <p class="text-center tracking-tighter">
                Diversifica tu negocio con nuestro portafolio de productos,
                aumentas tus ganancias y llegas a nuevos clientes.
              </p>
            </div>
            <div class="hidden md:block separator mt-16"></div>
            <div class="mx-auto text-center mt-4 md:mt-16">
              <img
                src="../assets/multiproduct.png"
                class="mx-auto"
                alt="Productos innovadores"
                width="101"
              />
              <h3 class="leading-none tracking-tighter mt-4 font-bold text-3xl">
                Multiproducto
              </h3>
              <p class="text-center tracking-tighter">
                Conéctate con los productos más innovadores para que tu negocio
                esté constantemente en crecimiento.
              </p>
            </div>
          </div>
        </section>

        <!-- multiproducts -->
        <section id="multiproduct">
          <div
            class="products-wrapper sm:-mt-24 md:mt-10  products-responsive flex justify-center"
          >
            <div class="mt-10">
              <div class="flex">
                <div class="text-right-products">
                  <h2>
                    ¡Todos nuestros <br class="hidden md:block" />
                    productos a tu alcance!
                  </h2>
                  <h3 class="white-subtitle">
                    Apuestas
                  </h3>
                  <div class="flex md:block">
                    <p class="white-paragraph">
                      Los mejores productos de suerte están aquí. Encuentra
                      <br class="hidden md:block" />
                      loterías apuestas deportivas, baloto y muchos más.
                    </p>
                  </div>
                </div>

                <div class="hidden md:block">
                  <img
                    src="../assets/bets.png"
                    class="mt-10"
                    alt="Apuestas digitales"
                    width="400"
                  />
                </div>

                <div class="hidden md:block">
                  <img
                    src="../assets/bets-providers.png"
                    width="100"
                    class=" mt-52 ml-10"
                    alt="Proveedores de apuestas"
                  />
                </div>
              </div>

              <div class="flex">
                <div class="hidden md:block">
                  <img
                    src="../assets/insurance-providers.png"
                    width="97"
                    alt="Proveedores de seguros"
                  />
                </div>
                <div class="hidden md:block">
                  <img
                    class="img-insurance ml-12"
                    src="../assets/insurance.png"
                    alt="Plataforma para pago de seguros"
                    width="440"
                  />
                </div>
                <div class="text-left-products">
                  <h3 class="white-subtitle">Seguros</h3>
                  <p class="white-paragraph">
                    Nos hemos aliado con las mejores aseguradoras.
                    <br class="hidden md:block" />
                    Vende seguros de vida y SOAT.
                  </p>
                </div>
              </div>
              <div class="flex">
                <div class="text-right-topup">
                  <h3 class="white-subtitle">
                    Recargas y <br class="block md:hidden" />
                    paquetes
                  </h3>
                  <p class="white-paragraph">
                    Todos los operadores del país se encuentran con
                    <br class="hidden md:block" />
                    nosotros. Accede a ellos a través de nuestra plataforma.
                  </p>
                </div>
                <div class="hidden md:block">
                  <img
                    class="img-topup"
                    src="../assets/topups.png"
                    width="369"
                    alt="Plataforma para realizar recargas"
                  />
                </div>
                <div class="hidden md:block">
                  <img
                    class="img-topup -mt-16 ml-12"
                    width="65"
                    src="../assets/topup-providers.png"
                    alt="Proveedores de recargas"
                  />
                </div>
              </div>
            </div>
          </div>
        </section>
        <router-link :to="{ name: 'multiproduct' }">
          <div class="flex justify-center">
            <button class="btn-light-gray">
              Ver más
            </button>
          </div>
        </router-link>
        <!-- testimonies -->

        <section>
          <div class="block md:hidden title-responsive">
            <h2 class="main-title">
              ¿Qué piensan <br />
              nuestros clientes?
            </h2>
            <p class="paragraph-responsive">
              Tu opinión es muy importante para nosotros, cuentanos tu
              experiencia en Instagram y Facebook.
            </p>
            <div class="flex justify-end mt-2">
              <div
                class="cursor-pointer"
                @click="
                  redirect('https://www.facebook.com/SomosRefacilOficial')
                "
              >
                <img
                  src="../assets/icons/fb.png"
                  width="35"
                  alt="Alto impacto financiero"
                />
              </div>
              <div
                class="ml-2 cursor-pointer"
                @click="redirect('https://www.instagram.com/somosrefacil')"
              >
                <img
                  src="../assets/icons/ig.png"
                  width="36"
                  alt="Plataforma mejor puntuada"
                />
              </div>
            </div>
          </div>
          <div class="box-testimony">
            <div class="flex">
              <div class="testimony-container">
                <div class="flex">
                  <div>
                    <div class="card-wrapper">
                      <img
                        src="../assets/quotation-marks.png"
                        width="121"
                        alt="Mejor plataforma de finanzas"
                      />
                      <div class="dialog-polygon">
                        <p>
                          “Me parece una compañía muy completa en cuanto a los
                          servicios que prestan. Como la plataforma lo dice
                          Refácil, de verdad es muy fácil y con mucha comodidad
                          para prestar un gran y buen servicio al público”
                        </p>
                        <h4>MARIA VITALIA VIVEROS</h4>
                        <p class="pdv">Punto de venta</p>
                      </div>
                    </div>
                    <div class="card-wrapper mt-16">
                      <img
                        src="../assets/quotation-marks.png"
                        width="121"
                        alt="Plataforma mas amigable"
                      />
                      <div class="dialog-polygon">
                        <p>
                          “Me parece muy buena plataforma, cargan con rapidez
                          las consignaciones, han aumentados los servicios y el
                          porcentaje de ganancia es bueno, la forma de sacar las
                          ganancias de plataforma es genial”
                        </p>
                        <h4>OSCAR OSWALDO VILLAMIL</h4>
                        <p class="pdv">Punto de venta</p>
                      </div>
                    </div>
                    <div class="card-wrapper block mt-16 md:hidden">
                      <img
                        src="../assets/quotation-marks.png"
                        width="121"
                        alt="Plataforma mejor puntuada"
                      />
                      <div class="dialog-polygon">
                        <p>
                          “Excelente servicios, nos ha brindado una oportunidad
                          buena y perfecta para poder seguir trabajando en medio
                          de una pandemia mundial. Gracias Refácil por hacer
                          todo más fácil”
                        </p>
                        <h4>ELIANA SALCEDO</h4>
                        <p class="pdv">Punto de venta</p>
                      </div>
                    </div>
                  </div>
                  <div class=" ml-4 hidden md:block">
                    <div class="card-wrapper mt-32 ml-10">
                      <img
                        src="../assets/quotation-marks.png"
                        width="121"
                        alt="Finanzas con tecnologia"
                      />
                      <div class="dialog-polygon ">
                        <p>
                          “Excelente servicios, nos ha brindado una oportunidad
                          buena y perfecta para poder seguir trabajando en medio
                          de una pandemia mundial. Gracias Refácil por hacer
                          todo más fácil”
                        </p>
                        <h4>ELIANA SALCEDO</h4>
                        <p class="pdv">Punto de venta</p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div class="testimony-title hidden md:block">
                <h2>
                  ¿Qué piensan <br />
                  nuestros clientes?
                </h2>
                <p>
                  Tu opinión es muy importante para nosotros, cuentanos <br />
                  tu experiencia en Instagram y Facebook.
                </p>
                <div class="flex justify-end mt-2">
                  <div
                    class="cursor-pointer"
                    @click="
                      redirect('https://www.facebook.com/SomosRefacilOficial')
                    "
                  >
                    <img
                      src="../assets/icons/fb.png"
                      width="35"
                      alt="Mejor plataforma financiera"
                    />
                  </div>
                  <div
                    class="ml-2 cursor-pointer"
                    @click="redirect('https://www.instagram.com/somosrefacil')"
                  >
                    <img
                      src="../assets/icons/ig.png"
                      width="36"
                      alt="Soluciones digitales para negocios"
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </div>
      <div class="hidden md:block wrapper-gray"></div>
    </div>

    <!-- footer -->
    <app-footer />

    <template v-if="showModalContact">
      <div
        class="overflow-x-hidden overflow-y-auto fixed inset-0 z-50 outline-none focus:outline-none justify-center items-center flex m"
      >
        <div class="relative w-full my-6 mx-auto">
          <!--content-->
          <div
            class="border-0 rounded-lg shadow-lg relative flex flex-col
          bg-white outline-none focus:outline-none mx-auto"
            style="width:340px; "
          >
            <button
              v-on:click="showModalContact = false"
              class="absolute btn-close-modal bgGray w-8 h-8 rounded-full leading-tight"
            >
              <i class="material-icons  text-white text-md mt-1 font-semibold"
                >close</i
              >
            </button>

            <div class="relative py-6 px-2 flex-auto">
              <button
                v-on:click="showCellphone = !showCellphone"
                class="btn-gray-main w-4/5 mx-auto block"
              >
                Número de contacto
              </button>
              <transition name="fade">
                <template v-if="showCellphone">
                  <div class="rounded-lg w-4/5 mx-auto p-3">
                    <h3 class=" font-bold text-2xl text-yellow-400 text-center">
                      317 230 9003
                    </h3>
                  </div>
                </template>
              </transition>
              <a
                href="https://wa.me/573172309003"
                target="_blank"
                class="block btn-light-main mx-auto w-4/5 mt-4"
              >
                Chatea con nosotros
                <span class="inline-block align-middle ml-2">
                  <img
                    src="../assets/icons/whatsapp.png"
                    alt="Plataforma para aumentar ganancias"
                    class="w-6"
                /></span>
              </a>
            </div>
          </div>
        </div>
      </div>
      <div
        v-if="showModalContact"
        class="opacity-25 fixed inset-0 z-40 bg-black"
      ></div>
    </template>
  </div>
</template>

<script>
import AppNav from '@/components/AppNav.vue'
import AppFooter from '@/components/AppFooter.vue'
export default {
  data() {
    return {
      showModalContact: false,
      showCellphone: false,
    }
  },
  components: {
    AppNav,
    AppFooter,
  },
  methods: {
    redirectWithAd(url) {
      let link = localStorage.ad ? `${url}?ad=${localStorage.ad}` : url
      window.open(link, '_blank')
    },
  },
}
</script>
<style lang="scss" scoped>
.bgGray {
  background: var(--darkGray);
}
.btn-light-gray {
  margin-top: -205px;
  font-size: 1.2rem;
  letter-spacing: 0;
  position: absolute;
  z-index: 2;
  color: var(--darkGray);
  padding: 1rem 5rem;
  border-radius: 2rem;
  background: #eee;
  transition: 0.3s;
}
.btn-light-gray:hover {
  background: var(--darkGray);
  color: #fff;
}

.nav-wrapper {
  justify-content: center;
  position: relative;
  min-height: 600px;
  &:before {
    content: '';
    position: absolute;
    top: -80px;
    left: 50%;
    width: 150vw;
    height: 100%;
    transform: translate(-50%, -55%) rotate(-12deg) skew(-5deg);
    background: linear-gradient(45deg, #dc9120, #ffd91f);
    z-index: -1;
    margin: 200px 0;
  }
}

.products-wrapper {
  position: relative;
  min-height: 1200px;
  height: auto;

  &:before {
    content: '';
    position: absolute;
    top: 10%;
    left: -25%;
    width: 150%;
    height: 75%;
    transform: rotate(9deg) skew(12deg);
    background: linear-gradient(45deg, #2a6391, #6fbfe1);
    z-index: -1;
  }

  .products-responsive-md {
    height: 80%;
    margin-top: 850px;
  }
}
.wrapper-gray {
  position: absolute;
  margin-top: -480px;
  z-index: -2;
  background-size: 100% auto;
  height: 600px;
  background-repeat: no-repeat;
  background-image: url('../assets/shape.png');
  width: 100%;
}
.box-testimony {
  padding: 0 5%;
}
.testimony-container {
  width: 60%;
  margin-top: -40px;
}
.testimony-container .card-wrapper {
  -webkit-box-shadow: 5px 5px 11px -2px rgba(99, 99, 99, 0.29);
  -moz-box-shadow: 5px 5px 11px -2px rgba(99, 99, 99, 0.29);
  box-shadow: 5px 5px 11px -2px rgba(99, 99, 99, 0.29);
  background: #fff;
  height: 180px;
  width: 310px;
  border-top-right-radius: 1rem;
  border-top-left-radius: 1rem;
  border-bottom-right-radius: 1rem;
  margin-left: 30px;
}
.testimony-container .dialog-polygon {
  background: #fff;
  border-top-right-radius: 1rem;
  border-top-left-radius: 1rem;
  border-bottom-right-radius: 5rem;
  border-bottom-right-radius: 5rem;
  clip-path: polygon(0% 0%, 100% 0%, 100% 82%, 10% 82%, 0 100%);
  padding: 2.5rem 2rem 3rem 2rem;
  text-align: center;
}
.testimony-container img {
  position: absolute;
  margin-top: -30px;
  margin-left: -30px;
  z-index: 2;
}
.card-wrapper p {
  letter-spacing: -0.05rem;
  line-height: 1rem;
  font-size: 0.8rem;
}
.card-wrapper h4 {
  margin-top: 1rem;
  font-size: 0.6rem;
  font-weight: 700;
  letter-spacing: 2px;
  line-height: 0.5rem;
}
.card-wrapper .pdv {
  font-size: 0.6rem;
  letter-spacing: 2px;
}

.testimony-title {
  margin-right: 1rem;
  margin-left: 3rem;
  text-align: right;
  h2 {
    margin-top: 40%;
    font-size: 46px;
    line-height: 2.9rem;
    font-weight: 700;
    letter-spacing: -0.18rem;
  }
  p {
    margin-top: 0.4rem;
    letter-spacing: -0.075rem;
    line-height: 1rem;
    font-size: 0.9rem;
  }
}

.products-wrapper {
  position: relative;
  min-height: 1200px;
  padding: 30px 10px;
  height: auto;
}
.box-testimony {
  display: block;
  width: 100%;
  // margin-top: 325px;

  .testimony-wrapper {
    background: #eee;
    border-radius: 20px;
    padding: 40px 20px;
    position: relative;

    &:before {
      content: '';
      position: absolute;
      bottom: -30px;
      left: 0;
      width: 0;
      height: 0;
      border-right: 30px solid transparent;
      border-top: 30px solid #eee;
      border-left: 30px solid #eee;
      border-bottom: 30px solid transparent;
    }
  }
}

.align-items {
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.btn-close-modal {
  right: -8px;
  top: -10px;
  z-index: 10;
}

.container-title {
  margin-top: 1rem;
  margin-left: 23%;
  .btn-gray-main {
    font-size: 1.1rem;
    font-weight: 700;
    padding: 0.8rem 1.8rem;
    margin-right: 1rem;
  }
  .btn-light-main {
    font-size: 1.1rem;
    padding: 0.75rem 1.8rem;
  }
}

.allies-p {
  font-size: 0.8rem;
  letter-spacing: 2px;
  font-weight: 600;
}

.icon-arrow {
  width: 100%;
  margin-bottom: 3.5rem;
  justify-content: center;
}
.container-items {
  padding: 0 10%;
}
.container-items .separator {
  width: 1px;
  height: 88px;
  background: #e9e9e9;
}
.container-items p {
  font-size: 0.95rem;
  margin-top: 0.7rem;
  line-height: 1rem;
  padding: 0 8%;
}

.text-right-products {
  padding-top: 6rem;
  color: #fff;
  text-align: right;
}
.text-right-topup {
  width: 50%;
  color: #fff;
  padding-right: 1rem;
  text-align: right;
}
.text-left-products {
  color: #fff;
  text-align: left;
}
.text-right-products h2 {
  font-size: 48px;
  letter-spacing: -0.2rem;
  line-height: 3rem;
  font-weight: 700;
}
.white-subtitle {
  margin-top: 1rem;
  font-size: 35px;
  line-height: 3rem;
  font-weight: 700;
  letter-spacing: -0.15rem;
}
.white-paragraph {
  font-size: 1rem;
  letter-spacing: -0.075rem;
  line-height: 1.2rem;
}
.img-insurance {
  margin-top: -20%;
}
.img-topup {
  margin-top: -40%;
}

@media (max-width: 1024px) {
  .container-title {
    margin-top: 0rem;
    margin-left: 8%;
    p {
      width: 90%;
    }
  }
  .img-initial {
    margin-right: 0;
  }

  .text-right-products {
    text-align: center;
    h2 {
      font-size: 2.8rem;
    }
  }
  .text-left-products,
  .text-right-topup {
    text-align: center;
  }

  .responsive-img {
    min-width: 50px;
    margin-top: -10px;
    margin-left: 20px;
  }
  .text-right-topup {
    width: 100%;
    padding: 0;
  }
  .white-subtitle {
    line-height: 2.3rem;
    margin-top: 3rem;
    margin-bottom: 0.5rem;
  }
  .products-wrapper {
    padding: 0 5%;
    min-height: 950px;
  }
  .btn-light-gray {
    margin-top: -175px;
  }
  .box-testimony {
    display: flex;
    justify-content: center;
    padding: 0;
  }
  .title-responsive {
    text-align: right;
    padding: 0 5% 100px 3%;
  }
  .paragraph-responsive {
    letter-spacing: -0.075rem;
    line-height: 1rem;
    font-size: 0.9rem;
  }
  .nav-wrapper {
    &:before {
      height: 100%;
      margin: 250px 0;
    }
  }
}
</style>

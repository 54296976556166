import { PSE_STATUS, FETCH_BANKS_PSE, ACTION_BRAND } from '../actions.type';
import { SET_BRAND } from '../mutation.type';
import ApiService from '@/common/api.service';

const state = {
	brand: '',
};
const getters = {
	brand(state) {
		return state.brand;
	},
};

const actions = {
	[PSE_STATUS](_, data) {
		return ApiService.postApagar('v1/transactions/status', data);
	},
	[FETCH_BANKS_PSE](context, data) {
		return ApiService.post('v2/transactions/banks/pse', data);
	},
	[ACTION_BRAND](context, data) {
		context.commit(SET_BRAND, data.brand);
	},
};

const mutations = {
	[SET_BRAND](state, brand) {
		state.brand = brand;
	},
};

export default {
	state,
	getters,
	mutations,
	actions,
};

<template>
  <div>
    <template v-if="show">
      <div
        class="overflow-x-hidden overflow-y-auto fixed inset-0 z-50 outline-none focus:outline-none justify-center items-center flex m"
      >
        <div class="relative w-full my-6 mx-auto">
          <!--content-->
          <div
            class="border-0 rounded-lg shadow-lg relative flex flex-col
          bg-white outline-none focus:outline-none mx-auto"
            style="width:340px; "
          >
            <button
              v-on:click="show = false"
              class="absolute btn-close-modal bg-gray-800 w-7 h-7 rounded-full leading-tight"
            >
              <i class="material-icons  text-white text-sm font-semibold"
                >close</i
              >
            </button>

            <div class="relative py-6 px-2 flex-auto">
              <slot></slot>
            </div>
          </div>
        </div>
      </div>
      <div v-if="show" class="opacity-25 fixed inset-0 z-40 bg-black"></div>
    </template>
  </div>
</template>

<script>
export default {
  name: "app-modal",
  props: ["show"],

  methods: {}
};
</script>

<style lang="scss">
.btn-close {
  right: -25px;
  position: absolute;
  z-index: 1000000;
  background: #000000;
  font-weight: bold;
  color: white;
  font-size: 25px;
  width: 40px;
  height: 40px;
  border-radius: 100px;
  top: -15px;
  box-shadow: 0px 0px 30px rgba(0, 0, 0, 0.36);
  border: none;
  line-height: 5px;
}
</style>

<template>
  <section :tabindex="1">
    <template v-if="type == 'multiselect-input'">
      <div
        class="container-select"
        :class="{ backgroundFixed: open }"
        @click="open = !open"
      >
        <div class="relative">
          <input
            @blur="bounceBlur"
            @input="filterItems"
            type="text"
            :tabindex="0"
            v-model="searchItem"
            @keydown.down="nextItem"
            @keydown.up="previousItem"
            @keydown.enter="
              selected = filterOptions[indexItem]
              $emit('select', selected)
              searchItem = selected[model]
              open = false
            "
            :placeholder="selected ? selected[model] : placeholder"
          />

          <div class="items" v-show="open">
            <div
              :tabindex="1"
              v-for="(option, i) of filterOptions"
              :key="i"
              @click="
                selected = option
                searchItem = selected[model]
                $emit('select', selected)
              "
              @mouseenter="indexItem = i"
            >
              <div
                class="item"
                :class="{
                  'hover-item': i === indexItem,
                  'border-rounded-item': indexItem === filterOptions.length - 1,
                }"
              >
                <p>{{ option[model] }}</p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </template>
  </section>
</template>

<script>
export default {
  name: 'AppSearchSelect',
  props: {
    label: { type: String, required: false },
    type: { type: String, required: false, default: 'multiselect' },
    placeholder: { type: String, required: false },
    options: { type: Array, required: true },
    default: { type: Object, required: false, default: null },
    model: { type: String, required: false },
    maxItems: { type: Number, required: false, default: 7 },
  },
  data() {
    return {
      selected: this.default
        ? this.default
        : this.options.length > 0
        ? this.options[0]
        : null,
      open: false,
      searchItem: '',
      filterOptions: [],
      indexItem: 0,
    }
  },
  mounted() {
    this.filterOptions =
      this.options.length > this.maxItems
        ? this.options.slice(0, this.maxItems)
        : this.options
  },

  methods: {
    bounceBlur() {
      this.open = false
      setTimeout(() => {
        this.open = false
      }, 300)
    },

    filterItems() {
      this.open = true
      this.filterOptions = this.options.filter((item) =>
        item[this.model].toLowerCase().match(this.searchItem.toLowerCase())
      )
    },
    nextItem() {
      if (this.maxItems >= this.indexItem + 2) {
        this.indexItem++
      }
    },
    previousItem() {
      if (this.indexItem > 0) {
        this.indexItem--
      }
    },
  },
  watch: {
    filterOptions() {
      if (this.filterOptions.length > this.maxItems) {
        this.filterOptions = this.filterOptions.slice(0, this.maxItems)
      } else if (this.indexItem >= this.filterOptions.length) {
        this.indexItem = this.filterOptions.length - 1
      }
    },
  },
}
</script>
<style lang="scss" scoped>
.items {
  box-shadow: -2px 7px 6px 2px rgba(128, 98, 96, 0.16);
  background: #fff;
  z-index: 1;
  border-radius: 0 0 8px 8px;
  padding-top: 1rem;
  width: 99.6%;
  margin-top: -8px;
  position: absolute;

  .item {
    color: var(--primary-gray);
    letter-spacing: -0.05rem;
    font-size: 0.95rem;
    text-align: left;
    color: rgb(0, 0, 0);
    padding: 0.4rem 0;
    padding-left: 2em;
    cursor: pointer;
    user-select: none;
  }
}

.container-select {
  input {
    width: 100%;
    background: #fff;
    padding: 1rem;
    border-radius: 0.5rem;
  }
  .hover-item {
    background: #dfdfdf;
  }
  .border-rounded-item {
    border-radius: 0 0 8px 8px;
  }
}
</style>

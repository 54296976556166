<template>
  <div class="multiproduct mt-10">
    <div class="main-container home-multiproduct mt-10">
      <div class="flex">
        <div class="md:w-1/2">
          <div class="container-title">
            <h2 class="main-title">
              Somos el aliado perfecto <br class="hidden md:block" />
              para que tus ventas no <br class="hidden md:block" />
              paren de crecer.
            </h2>
            <p class="main-paragraph">
              Te ayudamos a crecer, aumentar tus ventas e innovar en tu negocio.
              Con nuestra plataforma atraerás nuevos clientes, pues tendrás una
              amplia variedad de productos y servicios para comercializar.
              Contarás con respaldo, seguridad y diferentes productos que te
              ayudarán a ganar más.
              <br /><br />
              ¡Estamos listos para tenerte como aliado!
            </p>
          </div>
        </div>
        <div class="container-computer hidden md:block">
          <img
            src="../assets/computer.png"
            alt="Crecimiento de ventas"
            width="1200"
          />
        </div>
      </div>
    </div>

    <div class="products-wrapper">
      <div class="main-container padding-wrapper">
        <div class="container-product-title">
          <h2 class="main-title">
            Gana dinero extra en <br class="hidden md:block" />
            tu negocio con Refacil.
          </h2>
          <p class="main-paragraph">
            En Refácil encuentras diferentes categorías de productos que puedes
            comercializar <br class="hidden md:block" />
            de forma fácil y segura. Tenemos las mejores marcas y grandes
            proveedores.
          </p>
        </div>
        <div class="md:flex ">
          <div class="left-products">
            <div class="product-item">
              <img
                src="../assets/icons/gambling.png"
                width="44"
                alt="Apuestas digitales"
              />
              <span>SOLO VERSION WEB (TEMPORAL)</span>
              <button class="btn-gray">Apuestas</button>
              <p>
                Marcas reconocidas de juegos de azar para vender en tu negocio.
                Recargas deportivas con las mejores casas de apuestas. Ventas de
                loterías nacionales y demás juegos de azar para que tus clientes
                disfruten en familia.
              </p>
            </div>

            <div class="product-item">
              <img
                src="../assets/icons/insurance.png"
                width="34"
                alt="Plataforma de apuestas"
              />
              <span>VERSIÓN WEB Y MOVIL</span>
              <button class="btn-gray">Seguros</button>
              <p>
                Expide el SOAT de contado o a crédito con SISTECREDITO, podrás
                darle a tu cliente la opción de llevarlo hasta por 6 cuotas
                quincenales. Vende también seguros de vida a través de la
                plataforma fácil y rápido.
              </p>
            </div>

            <div class="product-item">
              <img
                src="../assets/icons/topup.png"
                width="23"
                alt="Plataforma para recargas"
              />
              <span>VERSIÓN WEB Y MOVIL</span>
              <button class="btn-gray">Recargas y paquetes</button>
              <p>
                Vende desde la plataforma recargas de los operadores móviles
                nacionales más reconocidos. Encuentra variedad de paquetes de
                los diferentes operadores, con excelentes promociones.
              </p>
            </div>
          </div>

          <div class="hidden md:block mobile-container">
            <img
              src="../assets/cellphone.png"
              width="390"
              alt="Plataforma con version movil"
            />
          </div>
          <div class="right-products">
            <div class="product-item">
              <img
                src="../assets/icons/wallets.png"
                width="33"
                alt="Billeteras virtuales"
              />
              <span>VERSIÓN WEB Y MOVIL</span>
              <button class="btn-gray">Billeteras</button>
              <p>
                Las billeteras virtuales más conocidas de Colombia están
                disponibles en la plataforma para que realices recargas y
                retiros de dinero, de forma segura.
              </p>
            </div>

            <div class="product-item">
              <img
                src="../assets/icons/pins.png"
                width="50"
                alt="Pago de servicios"
              />
              <span>VERSIÓN WEB Y MOVIL</span>
              <button class="btn-gray">Pines</button>
              <p>
                En tu negocio podrás tener los pines de entretenimiento de
                juegos, música y tv online que todos buscan. Además tendrás
                pines de servicios públicos.
              </p>
            </div>

            <div class="product-item">
              <img
                src="../assets/icons/pays.png"
                width="38"
                alt="Pago de facturas"
              />
              <span>VERSIÓN WEB Y MOVIL</span>
              <button class="btn-gray">Pagos y recaudos</button>
              <p>
                Recibe el pago de las facturas de empresas públicas y privadas.
                Tienes más de 6000 convenios disponibles en plataforma para
                recaudar.
              </p>
            </div>

            <div class="product-item mb-48">
              <img
                src="../assets/icons/certificates.png"
                width="40"
                alt="Pago de seguros"
              />
              <span>VERSIÓN WEB Y MOVIL</span>
              <button class="btn-gray">Certificados</button>
              <p>
                Expedición de certificados como RUNT y certificado de libertad y
                tradición de forma rápida y totalmente segura, con información
                completa y veraz.
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="app-container">
      <div class="flex main-container">
        <div>
          <div class="info-app main-container">
            <h2 class="main-title">Tus clientes te pagan de forma digital.</h2>
            <p>
              Con Refácil podrás recibir pagos digitales a través de diferentes
              medios que llevarán tu negocio a otro nivel. El QR es uno de los
              medios que incursionarán tus ventas, con el podrás recibir dinero
              de forma segura, tus clientes pagan desde sus aplicaciones de
              bancos favoritas o billeteras virtuales cualquier producto que
              lleven de tu negocio.
            </p>
            <span>
              Estos beneficios y muchos más están en Refácil. <br />
              Sácales su provecho a través de la aplicación
            </span>
            <button
              @click="
                redirect(
                  'https://play.google.com/store/apps/details?id=com.refacil&hl=es_CO&gl=US'
                )
              "
              class="btn-blue"
            >
              Descargar la App
            </button>
            <div class="flex">
              <div class="mr-1 mb-3">
                <img
                  width="170"
                  src="../assets/icons/google-play.png"
                  alt="Plataforma para android"
                />
              </div>
              <div>
                <img
                  width="170"
                  src="../assets/icons/app-store.png"
                  alt="Plataforma para ios"
                />
              </div>
            </div>
            <div>
              <img
                width="170"
                class="app-gallery"
                src="../assets/icons/app-gallery.png"
                alt="Plataforma para App Gallery"
              />
            </div>
          </div>
        </div>
        <div class="hidden md:block app-img-container">
          <img
            class="app-wallpaper"
            width="1050"
            src="../assets/app.png"
            alt="Mejor aplicacion transaccional"
          />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {}
  },
  components: {},
}
</script>
<style lang="scss" scoped>
.app-container {
  margin-top: -55px;
}
.home-multiproduct {
  min-height: 500px;
}
.app-img-container {
  min-height: 580px;
}
.info-app {
  position: absolute;
  padding-left: 8%;
  width: 50%;
  .btn-blue {
    display: block;
    padding: 0.7rem 2rem;
    border-radius: 2rem;
    color: #fff;
    background: linear-gradient(#2a6391, #6fbfe1);
    margin: 1rem 0 2rem;
  }

  p {
    line-height: 1.3rem;
    margin-top: 10px;
    letter-spacing: -0.075rem;
    font-weight: 300;
    margin-bottom: 1rem;
  }
  span {
    letter-spacing: -0.075rem;
    display: block;
    line-height: 1.3rem;
  }
}
.app-wallpaper {
  position: absolute;
  z-index: -2;
  right: 0;
  margin-top: -78px;
}
.container-title {
  position: absolute;
  padding-left: 8%;
  padding-top: 5rem;
  .main-paragraph {
    max-width: 32rem;
    font-size: 0.94rem;
    margin-top: 1rem;
    text-align: justify;
    text-justify: inter-word;
  }
}
.container-computer {
  z-index: -1;
  margin-right: -30px;
  margin-left: -40px;
}

.products-wrapper {
  .padding-wrapper {
    padding: 0 8%;
  }
  .container-product-title {
    margin-top: -30px;
    margin-bottom: 30px;
    color: #fff;
    padding-top: 0rem;
    p {
      margin-top: 0.5rem;
    }
  }
  .left-products {
    width: 35%;
  }
  .right-products {
    width: 36%;
  }

  .right-products {
    margin-top: -50px;
    padding-left: 6rem;
  }
  .left-products {
    padding-right: 5rem;
  }
  .product-item {
    margin-top: 2rem;
    span {
      margin-top: 0.3rem;
      margin-bottom: 0.3rem;
      letter-spacing: 1px;
      font-weight: 400;
      font-size: 0.6rem;
      display: block;
    }
    .btn-gray {
      font-weight: 700;
      font-size: 1.3rem;
      border-radius: 1rem;
      padding: 0rem 0.9rem;
      border: 1px solid var(--darkGray);
      transition: 0.5s;
    }
    .btn-gray:hover {
      cursor: default;
    }
    // Pendiente funcionamiento de botones
    // .btn-gray:hover {
    //   background: var(--darkGray);
    //   color: #fff;
    // }
    p {
      font-size: 0.85rem;
      line-height: 1rem;
      letter-spacing: -0.075rem;
      margin-top: 0.8rem;
    }
  }
}

.products-wrapper {
  position: relative;
  min-height: 600px;
  padding: 30px 10px;
  height: auto;

  &:before {
    content: '';
    position: absolute;
    top: -6%;
    left: -25%;
    width: 150%;
    height: 90%;
    transform: rotate(8deg) skew(12deg);
    background: linear-gradient(45deg, #dc9120, #ffd91f);
    z-index: -1;
    box-shadow: 0 6px 10px 0 #d4d4d440;
    margin-top: 20px;
  }
}

.screens-app {
  right: 0px;
  z-index: -2;
  width: 50%;
}

.underline-radius {
  border: 1px solid var(--darkGray);
  border-radius: 20px;
}

@media (max-width: 1024px) {
  .home-multiproduct {
    min-height: 340px;
  }
  .container-title {
    .main-title {
      font-size: 2.65rem;
      line-height: 2.5rem;
    }
    width: 100%;
    position: relative;
    padding: 2rem 7% 0 7%;
    .main-paragraph {
      max-width: 32rem;
      font-size: 0.94rem;
      margin-top: 1rem;
      text-align: justify;
      text-justify: inter-word;
    }
  }
  .products-wrapper {
    margin-top: 3rem;
    text-align: left;
    &:before {
      top: -1%;
    }
    .left-products,
    .right-products {
      width: 100%;
    }
    .right-products {
      margin-top: 0px;
      padding-left: 0rem;
    }
    .left-products {
      padding-right: 0rem;
    }
    .container-product-title {
      margin-top: 0;
      margin-bottom: 0;
      .main-title {
        font-size: 2.8rem;
        line-height: 2.8rem;
      }
    }
  }
  .info-app {
    position: relative;
    margin: 0;
    padding: 0 5%;
    width: 100%;
    margin-top: -80px;
    .main-title {
      line-height: 3.2rem;
    }
    .btn-blue {
      display: block;
      padding: 0.7rem 2rem;
      border-radius: 2rem;
      color: #fff;
      background: linear-gradient(#2a6391, #6fbfe1);
      margin: 1rem 0 2rem;
    }

    p {
      line-height: 1.3rem;
      margin-top: 10px;
      letter-spacing: -0.075rem;
      font-weight: 300;
      margin-bottom: 1rem;
    }
    span {
      letter-spacing: -0.075rem;
      display: block;
      line-height: 1.3rem;
    }
  }
  .app-img-container {
    width: 50%;
  }
}
</style>

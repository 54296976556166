<template>
  <div class="container">
    <template v-if="payment">
      <div
        class="mt-12 md:mt-24 px-6 pb-8 block md:hidden"
        style="border-bottom: 0.5px solid #a0afbf"
      >
        <div class="text-center">
          <h2 class="font-bold text-xl tracking-tighter">
            Resumen de pago
          </h2>
          <p class="tracking-wider text-sm text-center">TU COMPRA</p>
        </div>
        <div class="grid grid-cols-2 gap-6 mt-6">
          <div>
            <p class="text-md">
              <strong>Valor:</strong> ${{ money(payment.amount) }}
            </p>

            <p class="text-md">
              <strong>Costo transacción:</strong> ${{ money(payment.fee) }}
            </p>
          </div>
          <div>
            <p class="text-md">
              <strong>Impuestos:</strong> ${{ money(payment.tax) }}
            </p>
            <p class="text-md">
              <strong>Total:</strong> ${{ money(payment.total) }}
            </p>
          </div>
        </div>
      </div>
      <div class="grid grid-cols-3 mt-3">
        <div class="md:col-span-2  col-span-3 mt-5">
          <div class="box-form">
            <h2 class="font-bold text-4xl tracking-tighter">Proceso de pago</h2>
            <p>Selecciona una franquicia</p>

            <div class="grid grid-cols-6 gap-6 mt-6">
              <div class="col-span-2">
                <div class="box-shadow" v-on:click="methodPay = 'pse'">
                  <img
                    src="@/assets/payment/pse.png"
                    alt="Pagos por PSE"
                    class="mx-auto"
                  />
                </div>
              </div>
              <!-- <div class="col-span-2">
                <div class="box-shadow" v-on:click="methodPay = 'card'">
                  <img src="../assets/payment/american.png" alt="" class="mx-auto">
                </div>
              </div>
              <div class="col-span-2">
                <div class="box-shadow" v-on:click="methodPay = 'qr'">
                  <img src="../assets/payment/mastercard.png" alt="" class="mx-auto">
                </div>
              </div> -->
              <div class="col-span-2"></div>
              <div class="col-span-2"></div>
            </div>
            <!-- PSE -->
            <template v-if="methodPay === 'pse'">
              <p class="mt-5">Datos personales</p>
              <div class="grid grid-cols-1  md:grid-cols-3 gap-3 w-4/5">
                <div class="col-span-3">
                  <app-select v-model="form.bank" extraClass="input-light sm">
                    <option :value="bank.id" v-for="bank in banks" :key="bank.id">
                      {{ bank.name }}
                    </option>
                  </app-select>
                </div>

                <div class="col-span-3">
                  <app-select
                    v-model="form.typePerson"
                    extraClass="input-light sm"
                  >
                    <option :value="undefined" selected>
                      Tipo de persona
                    </option>
                    <option value="0">Natural</option>
                    <option value="1">Jurídica</option>
                  </app-select>
                </div>

                <div class="col-span-3">
                  <app-input
                    type="text"
                    placeholder="Nombre"
                    v-model="form.name"
                  />
                </div>

                <div class="col-span-3">
                  <app-input
                    type="text"
                    placeholder="Apellido"
                    v-model="form.lastName"
                  />
                </div>

                <div class="col-span-3 md:col-span-1">
                  <app-select
                    v-model="form.docType"
                    extraClass="input-light sm"
                  >
                    <option :value="undefined" disabled>
                      Tipo de documento
                    </option>
                    <option value="CC" selected>Cedula de ciudadania</option>
                    <option value="NIT">Nit</option>
                    <option value="3">Otro</option>
                  </app-select>
                </div>

                <div class="col-span-3 md:col-span-2">
                  <app-input
                    type="text"
                    placeholder="Número de documento"
                    v-model="form.docNumber"
                  />
                </div>

                <div class="col-span-3">
                  <app-input
                    type="number"
                    placeholder="Celular"
                    v-model="form.cellphone"
                  />
                </div>

                <div class="col-span-3">
                  <app-input
                    type="text"
                    placeholder="Correo eléctronico"
                    v-model="form.email"
                  />
                </div>
              </div>
            </template>

            <!-- card -->
            <template v-if="methodPay === 'card'">
              <div class="grid grid-cols-1  md:grid-cols-4 gap-3 w-4/5">
                <div class="col-span-4">
                  <app-select
                    v-model="form.typePerson"
                    extraClass="input-light sm"
                  >
                    <option :value="undefined" selected>
                      Tipo de persona
                    </option>
                    <option value="0">Natural</option>
                    <option value="1">Jurídica</option>
                  </app-select>
                </div>

                <div class="col-span-4">
                  <app-input
                    type="text"
                    placeholder="Nombre como aparece en la tarjeta"
                    v-model="form.name"
                  />
                </div>

                <div class="col-span-1">
                  <app-select
                    v-model="form.docType"
                    extraClass="input-light sm"
                  >
                    <option :value="undefined" selected>
                      C.C
                    </option>
                    <option value="CC">Cedula de ciudadania</option>
                    <option value="NIT">Nit</option>
                    <option value="3">Otro</option>
                  </app-select>
                </div>

                <div class="col-span-1 md:col-span-3">
                  <app-input
                    type="text"
                    placeholder="Número de documento"
                    v-model="form.docNumber"
                  />
                </div>

                <div class="col-span-4">
                  <app-input
                    type="number"
                    placeholder="Número de la tarjeta"
                    v-model="form.numberCard"
                  />
                </div>

                <div class="col-span-1">
                  <app-select v-model="form.month" extraClass="input-light sm">
                    <option :value="undefined" selected>
                      MM
                    </option>
                    <option value="1">1</option>
                    <option value="2">2</option>
                    <option value="3">3</option>
                    <option value="4">4</option>
                    <option value="5">5</option>
                    <option value="6">6</option>
                    <option value="7">7</option>
                    <option value="8">8</option>
                    <option value="9">9</option>
                    <option value="10">10</option>
                    <option value="11">11</option>
                    <option value="12">12</option>
                  </app-select>
                </div>

                <div class="col-span-1">
                  <app-select v-model="form.year" extraClass="input-light sm">
                    <option :value="undefined" selected>
                      AA
                    </option>
                    <option value="21">21</option>
                    <option value="22">22</option>
                    <option value="23">23</option>
                    <option value="24">24</option>
                    <option value="25">25</option>
                    <option value="26">26</option>
                    <option value="27">27</option>
                    <option value="28">28</option>
                    <option value="28">29</option>
                  </app-select>
                </div>

                <div class="col-span-1">
                  <app-input
                    type="number"
                    placeholder="CVV / CVC"
                    v-model="form.cvv"
                  />
                </div>

                <div class="col-span-1">
                  <app-select v-model="form.quotes" extraClass="input-light sm">
                    <option :value="undefined" selected>
                      Cuotas
                    </option>
                    <option value="1">1</option>
                    <option value="2">2</option>
                    <option value="3">3</option>
                    <option value="4">4</option>
                    <option value="5">5</option>
                    <option value="6">6</option>
                    <option value="7">7</option>
                    <option value="8">8</option>
                    <option value="9">9</option>
                    <option value="10">10</option>
                    <option value="11">11</option>
                    <option value="12">12</option>
                  </app-select>
                </div>

                <div class="col-span-4">
                  <app-input
                    type="number"
                    placeholder="Celular"
                    v-model="form.cellphone"
                  />
                </div>

                <div class="col-span-4">
                  <app-input
                    type="text"
                    placeholder="Correo eléctronico"
                    v-model="form.email"
                  />
                </div>
              </div>
            </template>

            <template v-if="methodPay">
              <div class="text-center mt-8">
                <button
                  class="btn btn-action"
                  :disabled="processing"
                  v-on:click="createPay"
                >
                  <!-- <template v-if="loading">
                    <app-loader />
                  </template>
                  <template v-else>
                    Pagar
                  </template> -->
                  Pagar
                </button>
              </div>
            </template>
          </div>
        </div>

        <div
          class="mt-5 col-span-3 md:col-span-1 hidden md:block border-gray-500 pt-12"
          style="border-left: 0.5px solid #a0afbf"
        >
          <div class="box-form container  mt-12">
            <div class="">
              <h2 class="font-bold text-4xl tracking-tighter">
                Resumen de pago
              </h2>
              <p class="tracking-wider text-center">TU COMPRA</p>
            </div>

            <div>
              <p class="text-lg my-6">{{ payment.observations }}</p>
              <p class="text-lg">
                <strong>Valor:</strong> ${{ money(payment.amount) }}
              </p>
              <p class="text-lg">
                <strong>Impuestos:</strong> ${{ money(payment.tax) }}
              </p>
              <p class="text-lg">
                <strong>Costo transacción:</strong> ${{ money(payment.fee) }}
              </p>
              <p class="text-lg">
                <strong>Total:</strong> ${{ money(payment.total) }}
              </p>
            </div>
          </div>
        </div>
      </div>

      <!-- <div class="footer text-center">
        SuperPagos - Todos los derechos reservados | Desarrollado por <a href="https://blackbozz.com" target="_blank">BlackBozz Group</a>
      </div> -->
    </template>

    <template v-else>
      <div class="container w-75">
        <div class="text-center mt-12">
          <img
            src="@/assets/icons/warning.png"
            alt="Mejor aplicacion transaccional"
            class="mx-auto"
          />
          <img
            class="networks w-50"
            src="https://plataforma.refacil.co/logo.png"
            alt="Inclusion financiera"
          />
          <div>
            <h3 class="font-bold title tracking-tighter">
              ¡Oops! Algo salió mal
            </h3>
            <h3 class="text-center mt-6">{{ message }}</h3>
          </div>
        </div>
      </div>
    </template>

    <app-modal>
      <img
        class="networks w-50"
        src="https://plataforma.refacil.co/logo.png"
        alt="Finanzas tecnologicas"
      />
      <div>
        <h2 class="text-center" style="color:#0e639a;">
          Finaliza el pago en la pasarela de PSE
        </h2>

        <div class="text-center my-3">
          <a :href="url">Si no te abrió ningún link pulsa aqui</a>
        </div>
      </div>
    </app-modal>

    <div>
      <!-- Image Map Generated by http://www.image-map.net/ -->
      <!-- <img
        src="../assets/website/socialNetworks.png"
        usemap="#image-map"
        class="networks"
      /> -->
      <map name="image-map">
        <area
          target="_blank"
          alt="Tecnologia financiera"
          title="Facebook"
          href="https://www.facebook.com/SomosRefacil/?__tn__=%2Cd%2CP-R&eid=ARD_wycNzQDuv_5MHumn7fQRG_SlpRC76BEo_395rPWyA957Q_919C1ro2h4j9Q3w0UuebIy0-4k0njs"
          coords="0,20,50,80"
          shape="rect"
        />
        <area
          target="_blank"
          alt="Aplicacion de finanzas"
          title="Instagram"
          href="https://www.instagram.com/somosrefacil/"
          coords="50,0,100,82"
          shape="rect"
        />
        <area
          target="_blank"
          alt="Plataforma transaccional"
          title=""
          href="https://www.youtube.com/channel/UCNiXy-BQQgITLcJdE2o8y6w"
          coords="100,0,150,85"
          shape="rect"
        />
      </map>
    </div>
  </div>
</template>
<script>
import { CREATE_PAYMENT, FETCH_PAYMENT, FETCH_BANKS_PSE } from "@/store/actions.type";
import AppModal from "@/components/general/AppModal";

export default {
  data() {
    return {
      form: {},
      message: "",
      loading: false,
      payment: null,
      processing: false,
      url: null,
      methodPay: "pse",
      banks: {}
    };
  },
  components: {
    AppModal
  },
  mounted() {
    this.getBanks();
    this.get();
  },
  methods: {
    getBanks() {
      this.$store
        .dispatch(FETCH_BANKS_PSE, { data: {} })
        .then(result => {
          this.banks = result.data.Banks.data.features;
        })
        .catch(err => {
          this.$snotify.error(err.message);
        });
    },
    get() {
      this.$store
        .dispatch(FETCH_PAYMENT, this.$route.params.id)
        .then((result) => {
          this.payment = result;
        })
        .catch((err) => {
          this.message = err.message;
        });
    },
    createPay() {
      // if (this.loading) return;
      // this.loading = true;
      this.form.id = this.$route.params.id;
      this.$store
        .dispatch(CREATE_PAYMENT, this.form)
        .then((data) => {
          window.open(data.url, "_blank");
          window.focus();
          //this.$refs.modalProcessing.show();
          // this.loading = false;
          this.url = data.url;
        })
        .catch((err) => {
          this.loading = false;
          this.$snotify.error(err.message);
        });
    }
  }
};
</script>
<style lang="scss">
.box-form {
  margin: 0 auto;
  padding: 20px 50px;
  border-radius: 30px;
  // box-shadow: 0 10px 10px rgba(0, 0, 0, 0.12);
  background: #fff;
}

.networks {
  display: block;
  width: 150px;
  margin: 20px auto;
}

.btn-action {
  font-size: 20px;
  font-weight: 600;
  padding: 5px 15px;
  -webkit-transition: 0.3s;
  transition: 0.3s;
  color: #fff;
  background: linear-gradient(45deg, #2a6397, #6fc5e7);
  border-radius: 50px;
  width: 50%;
}

.box-shadow {
  box-shadow: -3px 2px 4px rgba(0, 0, 0, 0.12);
  padding: 15px 10px;
  border-radius: 10px;
  cursor: pointer;
}
</style>

<template>
  <div class="main-container">
    <div class="container-map grid md:flex">
      <div class="map">
        <div class="w-full">
          <div class="map-google">
            <GmapMap
              :center="getPosition(coordinates[0])"
              :zoom="zoom"
              class="google-api"
              style=""
              map-type-id="terrain"
            >
              <gmap-marker
                v-for="(item, key) in coordinates"
                :key="key"
                :position="getPosition(item)"
                :clickable="true"
              />
            </GmapMap>
          </div>
        </div>
      </div>
      <div class="container-form ">
        <h3 class="secundary-title mt-3 mb-3">¿Donde quieres buscarnos?</h3>

        <input
          type="text"
          v-model="formData.address"
          placeholder="Plaza de Bolivar"
        />
        <div class="grid grid-cols-2">
          <div v-if="loadingStates" class="mr-2">
            <h3 class="secundary-title mt-3 mb-1">Departamento</h3>
            <app-search-select
              type="multiselect-input"
              placeholder="Departamento"
              :options="states"
              model="state"
              :maxItems="7"
              @select="selectState"
            />
          </div>

          <div class="ml-2">
            <h3 class="secundary-title mt-3 mb-1">Ciudad</h3>
            <div v-if="loadingCities">
              <app-search-select
                type="multiselect-input"
                placeholder="Ciudad"
                :options="cities"
                model="name"
                :maxItems="7"
                @select="selectCity"
              />
            </div>
            <div v-else>
              <input type="text" disabled />
            </div>
          </div>
        </div>
        <div class="mt-8">
          <button class="btn-gray-main" @click="uploadLocations">
            Buscar Ahora
          </button>
          <div v-if="notFoundAlert" class="w-full text-center">
            <small>No se encuentran puntos en esta ubicación</small>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      formData: {},
      zoom: 15,
      coordinates: {
        0: {
          address: 'Oficina Super Pagos',
          latitude: 4.814824,
          longitude: -75.694878,
        },
      },
      points: {},
      states: [],
      loadingStates: false,
      cities: [],
      loadingCities: false,
      notFoundAlert: false,
    }
  },

  mounted() {
    this.getStates()

    if ('geolocation' in navigator) {
      navigator.geolocation.getCurrentPosition((position) => {
        this.formData.latLng = `${position.coords.latitude},${position.coords.longitude}`
        this.getPointsWithGeolocation()
      })
    } else {
      console.log("Browser doesn't support geolocation!")
    }
  },
  methods: {
    alertNotFound() {
      this.notFoundAlert = true
      setTimeout(() => {
        this.notFoundAlert = false
      }, 2000)
    },
    getPointsWithGeolocation() {
      let body = {
        data: {
          latLng: this.formData.latLng,
        },
      }
      fetch('https://testing.refacil.co/api/v2/places/nearLanding', {
        method: 'POST',
        body: JSON.stringify(body),
        headers: { 'Content-type': 'application/json; charset=UTF-8' },
      })
        .then((response) => response.json())
        .then((json) => {
          if (json.statusCode === 200 && json.data.Points > 0) {
            this.zoom = 15
            this.coordinates = json.data.Points
          }
        })
        .catch((err) => console.log(err))
    },
    selectState(selected) {
      this.cities = []
      this.loadingCities = false
      this.getCities(selected.id)
      this.formData.stateName = selected.state
    },
    selectCity(selected) {
      this.formData.cityName = selected.name
    },
    formatElements(elements, model) {
      return elements.map((item) => {
        let formatItem = item[model].replace(/ /g, '')
        formatItem =
          formatItem.charAt(0).toUpperCase() + formatItem.slice(1).toLowerCase()
        return {
          id: item.id,
          [model]: formatItem,
        }
      })
    },

    getCities(stateId) {
      let body = {
        data: { stateId },
      }
      fetch('https://testing.refacil.co/api/v2/places/cities', {
        method: 'POST',
        body: JSON.stringify(body),
        headers: { 'Content-type': 'application/json; charset=UTF-8' },
      })
        .then((response) => response.json())
        .then((json) => {
          this.cities = this.formatElements(json.data.Cities, 'name')
          this.loadingCities = true
        })
        .catch((err) => console.log(err))
    },
    getStates() {
      let body = {
        data: {},
      }
      fetch('https://testing.refacil.co/api/v2/places/states', {
        method: 'POST',
        body: JSON.stringify(body),
        headers: { 'Content-type': 'application/json; charset=UTF-8' },
      })
        .then((response) => response.json())
        .then((json) => {
          this.states = this.formatElements(json.data.States, 'state')
          this.loadingStates = true
        })
        .catch((err) => console.log(err))
    },

    searchCitys() {
      this.showCitysWindow = true
      this.filterCitys = this.citys.filter((item) =>
        item.city.toLowerCase().match(this.formData.city)
      )
    },
    getPosition(marker) {
      return {
        lat: parseFloat(marker.latitude),
        lng: parseFloat(marker.longitude),
      }
    },

    uploadLocations() {
      let address = ''
      if (this.formData.stateName) {
        address += this.formData.stateName
      }
      if (this.formData.cityName) {
        address.length > 0
          ? (address += `,${this.formData.cityName}`)
          : (address += this.formData.cityName)
      }
      if (this.formData.address) {
        address.length > 0
          ? (address += `,${this.formData.address}`)
          : (address += this.formData.address)
      }

      let body = {
        data: {
          address,
        },
      }
      fetch('https://testing.refacil.co/api/v2/places/nearLanding', {
        method: 'POST',
        body: JSON.stringify(body),
        headers: { 'Content-type': 'application/json; charset=UTF-8' },
      })
        .then((response) => response.json())
        .then((json) => {
          if (json.statusCode === 200 && json.data.Points > 0) {
            this.zoom = 15
            this.coordinates = json.data.Points
          } else {
            this.alertNotFound()
          }
        })
        .catch((err) => console.log(err))
    },
  },
}
</script>

<style lang="scss" scoped>
.container-form {
  .btn-gray-main {
    width: 60%;
  }
  width: 70%;
  margin: 0 3%;
  .secundary-title {
    font-size: 1.5rem;
    letter-spacing: -0.08rem;
  }
  .grid {
    .secundary-title {
      font-size: 1.5rem;
    }
  }
  input {
    width: 100%;
    background: #fff;
    padding: 1rem;
    border-radius: 0.5rem;
  }
}
.google-api {
  width: 100%;
  height: 300px;
}

.map {
  display: flex;
  width: 100%;
  padding: 0 0 2rem 2rem;
  justify-content: center;
}
.map img {
  max-width: 617px;
  border-radius: 1rem;
}

.container-map {
  margin-top: 4rem;
  justify-content: center;
  padding: 1rem 5% 1rem 3%;
  width: 100%;
}

.container-map input,
.container-map .btn-gray-main {
  display: block;
  margin: 0 auto;
}

.map-google {
  width: 100%;
  margin: 0;
}
</style>

import Vue from 'vue';
import VueRouter from 'vue-router';
import Home from '../views/Home.vue';
import Dealers from '../views/Dealers.vue';
import AboutUs from '../views/AboutUs.vue';
import Multiproduct from '../views/Multiproduct.vue';
import BancolombiaQR from '../views/BancolombiaQR.vue';

import Payments from '../views/Pay/Payments.vue';
import PaymentStatus from '../views/Pay/PaymentStatus.vue';

import Points from '../views/Points.vue';

Vue.use(VueRouter);

const routes = [
	{
		path: '/',
		name: 'home',
		component: Home,
		meta: {
			title: 'Gana ingresos extras vendiendo multiproductos y recargas',
			layout: 'none',
			logo: 'logoW.png',
		},
	},

	{
		path: '/multiproducto/',
		name: 'multiproduct',
		component: Multiproduct,
		meta: {
			layout: 'default',
			title: 'Multiproducto',
			logo: 'logo.png',
		},
	},
	{
		path: '/distribuidores/',
		name: 'dealers',
		component: Dealers,
		meta: {
			layout: 'default',
			title: 'Distribuidores',
			logo: 'logo.png',
		},
	},
	{
		path: '/quienes-somos/',
		name: 'aboutUs',
		component: AboutUs,
		meta: {
			layout: 'default',
			title: 'Quiénes somos',
			logo: 'logo.png',
		},
	},
	{
		path: '/pagos/:id',
		name: 'payments',
		component: Payments,
		meta: {
			layout: 'app',
			title: 'Pagos',
			logo: 'logo.png',
		},
	},

	{
		path: '/transaction-status/:reference/:paymentMethod',
		name: 'pseStatus',
		component: PaymentStatus,
		meta: {
			layout: 'app',
			title: 'Trasaccion PSE',
			logo: 'logo.png',
		},
	},
	{
		path: '/transaction-status/:company/:reference/:paymentMethod/',
		name: 'pseStatus',
		component: PaymentStatus,
		meta: {
			layout: 'app',
			title: 'Trasaccion PSE',
			logo: 'logo.png',
		},
	},
	{
		path: '/puntos/',
		name: 'points',
		component: Points,
		meta: {
			layout: 'default',
			title: 'Puntos',
			logo: 'logo.png',
		},
	},
	{
		path: '/bancolombiaQR',
		name: 'Bancolombia QR',
		component: BancolombiaQR,
		meta: {
			layout: 'none',
			title: 'QR Bancolombia en tu negocio',
			logo: 'logo.png',
		},
	},
];

const router = new VueRouter({
	mode: 'history',
	base: process.env.BASE_URL,
	routes,
});

export default router;
